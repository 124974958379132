import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Header from "../Header";
import pic from "../../img/contact-start.png";
import DatePicker from "react-horizontal-datepicker";
import { ArrowBack } from "@material-ui/icons";

const Demo = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [blur, setBlur] = useState(1);

  const handleDateChange = (date) => {
    const dateObj = new Date(date);
    var month = dateObj.getMonth() + 1;
    var day = dateObj.getDate();
    var year = dateObj.getFullYear();
    setStartDate(date);
    setDay(day);
    setMonth(month);
    setYear(year);
    // console.log(day, month, year);
  };

  return (
    <div className="header-marg ">
      <Header />
      <div className="header-marg">
        <Row className="mx-0 padding-side py-5">
          <Col xs={12} lg={6}>
            <div className="mt-5">
              <li style={{ fontSize: "1.2rem", fontWeight: "600" }}>
                Book a demo
              </li>
              <img src={pic} width="400px" height="250px" alt="" />
            </div>
          </Col>
          <Col xs={12} lg={6} style={{ position: "relative", height: "500px" }}>
            <div
              className={`mt-4 ${
                blur === 1 ? "opacity-100 z1" : "opacity-0 z0"
              }`}
              style={{ maxWidth: "400px", position: "absolute", top: "0" }}
            >
              <DatePicker
                getSelectedDay={handleDateChange}
                endDate={100}
                selectDate={startDate}
                labelFormat={"MMMM yyyy"}
                color={"#374e8c"}
              />
            </div>
            <form
              action="https://docs.google.com/forms/d/e/1FAIpQLSdsSS-VMeabuLWpZPIv602_1mo55r9tgHXV0fQ68yHz_UjyQA/formResponse"
              method="POST"
            >
              <div
                className={`mt-4 ${
                  blur === 1 ? "opacity-100 z1" : "opacity-0 z0"
                }`}
                style={{
                  maxWidth: "500px",
                  position: "absolute",
                  top: "100px",
                }}
              >
                <input
                  type="hidden"
                  name="entry.342956603_day"
                  value={day}
                  id="day"
                />
                <input
                  type="hidden"
                  name="entry.342956603_month"
                  value={month}
                  id="month"
                />
                <input
                  type="hidden"
                  name="entry.342956603_year"
                  value={year}
                  id="year"
                />
                <div className="mt-5">
                  <div class="wrapper">
                    <input
                      type="radio"
                      name="entry.129391350"
                      value="2:00 PM"
                      id="option-1"
                    />
                    <input
                      type="radio"
                      name="entry.129391350"
                      value="4:00 PM"
                      id="option-2"
                    />
                    <li className="sol-market ">Afternoon</li>
                    <label
                      for="option-1"
                      class="option option-1 mt-2"
                      onClick={() => {
                        setBlur(2);
                      }}
                    >
                      <span>2 : 00 PM</span>
                    </label>
                    <li className="sol-market mt-4">Evening</li>
                    <label
                      for="option-2"
                      class="option option-2 mt-2"
                      onClick={() => {
                        setBlur(2);
                      }}
                    >
                      <span>4 : 00 PM</span>
                    </label>
                  </div>
                </div>
              </div>

              <div
                className={`contact-card p-4 ${
                  blur === 2 ? "opacity-100 z1" : "opacity-0 z0"
                }`}
                style={{ height: "450px", position: "absolute", top: "0" }}
              >
                <li className="text-center sol-market mb-2">
                  <ArrowBack
                    style={{ float: "left" }}
                    onClick={() => setBlur(1)}
                  />
                  Please Enter Your Details
                </li>
                <input type="text" placeholder="Name" name="entry.815198927" />
                <input
                  type="email"
                  placeholder="Email"
                  name="entry.1229745273"
                />
                <input
                  type="number"
                  placeholder="Phone number"
                  name="entry.705974865"
                />
                <textarea
                  placeholder="Comment"
                  rows="4"
                  name="entry.668113995"
                ></textarea>

                <div className="text-center mt-3">
                  <button className="btn-start py-2" name="fbzx">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Demo;

// date name="entry.342956603"
// time name="entry.129391350"
