import React from "react";
import Header from "../Header";
import Service from "./Service";
import SolutionPage from "./SolutionPage";

function Solution() {
  return (
    <div className="header-marg">
      <Header />
      <SolutionPage />
      <Service />
    </div>
  );
}

export default Solution;
