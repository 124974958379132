import React from "react";
import { Col, Row } from "react-bootstrap";
// import fb from "../img/fb.png";
// import twitter from "../img/twitter.png";
// import other from "../img/other.png";
// import linkedin from "../img/linkedin.png";
import { Facebook, Instagram, LinkedIn } from "@material-ui/icons";
import mail from "../img/mail.png";
import phone from "../img/phone.png";
import location from "../img/location.png";
import logo from "../img/footer-logo.png";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <div className="footer p-4">
        <Row className="mx-0">
          <Col xs={12} lg={4} className="mt-3">
            <div>
              <img src={logo} alt="" />
              <li className="mt-3" style={{ fontSize: "0.9rem" }}>
                {/* 9thBlock enhances the collaboration between Corporations, NGOs,
                Government Bodies and Volunteers with the core focus on driving
                positive change in our communities */}
                9th Block's mission is to enhance collaboration and improve
                transparency in social impact activities through technology and
                expert consultations.
              </li>
              {/* <div className="footer-social mt-3">
                <a href="https://www.facebook.com/9thblock-103229902139875">
                  <Facebook />
                </a>
                <a href="https://www.instagram.com/9thblockinc/">
                  <Instagram style={{ margin: "0 20px" }} />
                </a>
                <a href="https://www.linkedin.com/company/9thblock-inc">
                  <LinkedIn />
                </a>
              </div> */}
            </div>
          </Col>
          <Col xs={12} lg={2} className="mt-3">
            <div className="link-a">
              <br />
              <Link to="/about" className="mx-0">
                <li style={{ fontSize: "0.85rem", marginTop: "4px" }}>
                  About Us
                </li>
              </Link>
              {/* <Link to="/" className="mx-0">
                <li style={{ fontSize: "0.85rem",marginTop: "4px" }}>Products</li>
              </Link> */}
              <Link to="/solution" className="mx-0">
                <li style={{ fontSize: "0.85rem", marginTop: "4px" }}>
                  Solutions
                </li>
              </Link>
              <Link to="/resource" className="mx-0">
                <li style={{ fontSize: "0.85rem", marginTop: "4px" }}>
                  Resources
                </li>
              </Link>
              <Link to="/event" className="mx-0">
                <li style={{ fontSize: "0.85rem", marginTop: "4px" }}>
                  Events
                </li>
              </Link>
              <Link to="/contact" className="mx-0">
                <li style={{ fontSize: "0.85rem", marginTop: "4px" }}>
                  Contact us
                </li>
              </Link>
            </div>
          </Col>
          <Col xs={12} lg={2} className="mt-3">
            <div className="link-a">
              <li style={{ fontSize: "1.08rem", fontWeight: "600" }}>
                Products
              </li>
              <Link to="/edge" className="mx-0">
                <li style={{ fontSize: "0.85rem", marginTop: "4px" }}>Edge</li>
              </Link>
              <Link to="/hub" className="mx-0">
                <li style={{ fontSize: "0.85rem", marginTop: "4px" }}>Hub</li>
              </Link>
            </div>
          </Col>
          <Col xs={12} lg={2} className="mt-3">
            <div>
              <br />
              <li
                className="footer-send"
                style={{ fontSize: "0.85rem", marginTop: "4px" }}
              >
                send mail
              </li>
              <li style={{ fontSize: "0.85rem" }}>admin@9thblock.com</li>
              <li className="footer-send" style={{ fontSize: "0.85rem" }}>
                make call
              </li>
              <li style={{ fontSize: "0.85rem" }}>+91 99805 89868</li>
              <li style={{ fontSize: "0.85rem" }}>+1 (650)924-5372</li>
              <li className="footer-send" style={{ fontSize: "0.85rem" }}>
                get in touch
              </li>
              <li style={{ fontSize: "0.85rem" }}>Redwood City, CA, US</li>
            </div>
          </Col>
          <Col xs={12} lg={2} className="mt-3">
            <div className="footer-social mt-3">
              <a href="https://www.facebook.com/9thblock-103229902139875">
                <Facebook />
              </a>
              <a href="https://www.instagram.com/9thblockinc/">
                <Instagram style={{ margin: "0 20px" }} />
              </a>
              <a href="https://www.linkedin.com/company/9thblock-inc">
                <LinkedIn />
              </a>
            </div>
          </Col>
          {/* <Col>
            <Row>
              <Col xs={12} lg={4} className="mt-2">
                <img src={mail} alt="" />
                <li className="footer-send">send mail</li>
                <li>aakriti@9thblock.com</li>
              </Col>
              <Col xs={12} lg={4} className="mt-2">
                <img src={phone} alt="" />
                <li className="footer-send">make call</li>
                <li>+91 99805 89868</li>
                <li>+1 (650)924-5372</li>
              </Col>
              <Col xs={12} lg={4} className="mt-2">
                <img src={location} alt="" />
                <li className="footer-send">get in touch</li>
                <li>Redwood City, CA, US</li>
              </Col>
            </Row>
          </Col> */}
        </Row>
      </div>
      {/* <div className="text-center p-2">
        <li>2021 All Rights Reserved</li>
      </div> */}
    </>
  );
}

export default Footer;
