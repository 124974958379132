import React from "react";
import { Col, Row } from "react-bootstrap";
import effect2 from "../../img/contact-effect.png";
// import r1 from "../../img/r1.png";
// import r2 from "../../img/r2.png";
// import r3 from "../../img/r3.png";
import sol1 from "../../img/sol1.png";
import sol2 from "../../img/sol2.png";
import sol3 from "../../img/sol3.png";
import sol4 from "../../img/sol4.png";
import sol5 from "../../img/sol5.png";

function Blog() {
  return (
    <>
      {/* <div className="only-web">
        <div className="d-flex justify-content-between mt-5">
          <li className="res-cat">Education</li>
          <li className="res-cat">Women Empowernment</li>
          <li className="res-cat">Health</li>
          <li style={{ fontWeight: "650" }}>Reset Filter</li>
        </div>
      </div> */}

      {/* <div className="only-mob  mt-5">
        <select name="" id="" className="w-100 py-2 ">
          <option value="">Education</option>
          <option value="">Women Empowernment</option>
          <option value="">Health</option>
        </select>
      </div> */}
      <div className="res-card  blog-mob-top">
        <Row className="mx-0">
          <Col xs={12} lg={4} className="px-0">
            <img
              src={sol1}
              width="100%"
              height="100%"
              className="blog-img-top"
              alt=""
            />
          </Col>
          <Col xs={12} lg={8} className="px-0">
            <div className="p-3">
              {/* <div className="d-flex justify-content-between">
                <li className="benefit-title text-start">People</li>
                <li className="">5 min. read</li>
              </div> */}

              <li className="benefit-title mt-1">
                Digital Volunteering Platform
              </li>
              <li className="mt-2">
                In recent years, technology has become a driving force for
                economic and social change. It is not uncommon for non-profit
                making organizations (NGOs) to recruit volunteers without
                relying heavily on ICT (information and digital technology). To
                attract the most well-prepared and motivated volunteers,
                organizations in this sector should incorporate and use digital
                platforms. The perception of a technological platform is to give
                non-profits and volunteers access to information, tools, and
                opportunities.
              </li>
              <div className="d-flex justify-content-between mt-3">
                <a href="/solution/digital-volunteer">
                  <li className="sol-read ">Read Now..</li>
                </a>
                <li>
                  <span>Created by:</span>&nbsp;
                  <span style={{ fontWeight: "600", color: "#5e81e2" }}>
                    9thBlock
                  </span>
                </li>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <div className="res-card mt-5">
        <Row className="mx-0">
          <Col xs={12} lg={4} className="px-0">
            <img
              src={sol2}
              width="100%"
              height="100%"
              className="blog-img-top"
              alt=""
            />
          </Col>
          <Col xs={12} lg={8} className="px-0">
            <div className="p-3">
              {/* <div className="d-flex justify-content-between">
                <li className="benefit-title text-start">People</li>
                <li className="">5 min. read</li>
              </div> */}
              <li className="benefit-title mt-1">CSR Investment Management</li>
              <li className="mt-2">
                Donating time or materials to a charity or non-profit
                organization is the act of an insurance or investment group
                doing this to promote social wellbeing and not just to meet its
                own or shareholders' interests. The concept of CSR is becoming
                more integrated into how businesses operate since they must
                demonstrate the positive impact they are having on society, the
                environment, and their employees.
              </li>
              <div className="d-flex justify-content-between mt-3">
                <a href="/solution/csr-investment">
                  <li className="sol-read ">Read Now..</li>
                </a>
                <li>
                  <span>Created by:</span>&nbsp;
                  <span style={{ fontWeight: "600", color: "#5e81e2" }}>
                    9thBlock
                  </span>
                </li>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <img src={effect2} className="card-effect only-web" alt="" />

      <div className="res-card mt-5">
        <Row className="mx-0">
          <Col xs={12} lg={4} className="px-0">
            <img
              src={sol3}
              width="100%"
              height="100%"
              className="blog-img-top"
              alt=""
            />
          </Col>
          <Col xs={12} lg={8} className="px-0">
            <div className="p-3">
              {/* <div className="d-flex justify-content-between">
                <li className="benefit-title text-start">People</li>
                <li className="">5 min. read</li>
              </div> */}
              <li className="benefit-title mt-1">Technology for Non-Profits</li>
              <li className="mt-2">
                A lot has happened to this world over the last two or so
                decades, but one of the greatest events in technological
                development. Technology which is fundamental to making our lives
                better and more fulfilling has altered the world in a variety of
                ways. The positive influence of technology on globalisation
                continues to be seen. With technology at the centre of any
                business plan these days, the question isn't so much if you have
                it as when you should acquire it. We need to figure out how to
                make this information more accessible and inexpensive for
                sectors that need it more than ever.
              </li>
              <div className="d-flex justify-content-between mt-3">
                <a href="/solution/tech-for-non-profit">
                  <li className="sol-read ">Read Now..</li>
                </a>
                <li>
                  <span>Created by:</span>&nbsp;
                  <span style={{ fontWeight: "600", color: "#5e81e2" }}>
                    9thBlock
                  </span>
                </li>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <div className="res-card my-5">
        <Row className="mx-0">
          <Col xs={12} lg={4} className="px-0">
            <img
              src={sol4}
              width="100%"
              height="100%"
              className="blog-img-top"
              alt=""
            />
          </Col>
          <Col xs={12} lg={8} className="px-0">
            <div className="p-3">
              {/* <div className="d-flex justify-content-between">
                <li className="benefit-title text-start">People</li>
                <li className="">5 min. read</li>
              </div> */}
              <li className="benefit-title mt-1">Non-Profit Fundraising</li>
              <li className="mt-2">
                The goal of nonprofit fundraising is to help your organization
                grow while meeting the community's needs through donations and
                voluntary funds. Business and individual donors can both
                participate in your fundraising campaign.
              </li>
              <div className="d-flex justify-content-between mt-3">
                <a href="/non-profit-fundraising">
                  <li className="sol-read ">Read Now..</li>
                </a>
                <li>
                  <span>Created by:</span>&nbsp;
                  <span style={{ fontWeight: "600", color: "#5e81e2" }}>
                    9thBlock
                  </span>
                </li>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <div className="res-card my-5">
        <Row className="mx-0">
          <Col xs={12} lg={4} className="px-0">
            <img
              src={sol5}
              width="100%"
              height="100%"
              className="blog-img-top"
              alt=""
            />
          </Col>
          <Col xs={12} lg={8} className="px-0">
            <div className="p-3">
              {/* <div className="d-flex justify-content-between">
                <li className="benefit-title text-start">People</li>
                <li className="">5 min. read</li>
              </div> */}
              <li className="benefit-title mt-1">
                Searching right non-profit partner Domestic & International
              </li>
              <li className="mt-2">
                A strategic alliance can be formed by finding the right
                nonprofit partner for your corporation. A mutually beneficial
                relationship is forged through a complete alignment between both
                parties. Together, you and your nonprofit partner can build
                awareness and reach your goals - all while making a difference
                for the causes that matter most to you.
              </li>
              <div className="d-flex justify-content-between mt-3">
                <a href="/solution/searching-for-right-non-profit">
                  <li className="sol-read ">Read Now..</li>
                </a>
                <li>
                  <span>Created by:</span>&nbsp;
                  <span style={{ fontWeight: "600", color: "#5e81e2" }}>
                    9thBlock
                  </span>
                </li>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Blog;
