import React from "react";
import { Col, Row } from "react-bootstrap";
import sol5 from "../../img/sol5.png";

function SearchingForRight() {
  return (
    <div className="mt-5 padding-side">
      <li
        className=" text-center"
        style={{ fontWeight: "800", fontSize: "1.4rem" }}
      >
        Collaborating with the Most Suitable Non-Profit: Domestic and
        International
      </li>
      <Row className="mt-4">
        <Col xs={12} lg={6}>
          <li className="mt-3">
            A strategic alliance can be formed by finding the right nonprofit
            partner for your corporation. A mutually beneficial relationship is
            forged through a complete alignment between both parties. Together,
            you and your nonprofit partner can build awareness and reach your
            goals - all while making a difference for the causes that matter
            most to you.
          </li>
          <li className="mt-3">
            Companies have a variety of nonprofit partnership goals, and
            identifying a long-term partner in alignment with these goals takes
            more than a simple Google search. Getting started with corporate
            giving can be easy with these tips for partnering with a nonprofit.
          </li>

          <li className="mt-3" style={{ fontWeight: "800" }}>
            Can nonprofits add value?
          </li>
          <li className="mt-3">
            A non-profit organization's image is its most valuable asset.
            Cause-related marketing alliances are often formed so that a
            nonprofit's image will define, enhance, or repair a company's image.
          </li>
          <li className="mt-3">
            Corporations can benefit from nonprofits in ways beyond their image.
            The alliance between a corporation and a nonprofit often results in
            a corporation saving on marketing and advertising expenses, since
            the nonprofit usually provide free publicity and public relations
            opportunities. Furthermore, corporate clients gain access to the
            nonprofit's clients, employees, trustees, and donors, all of whom
            can become potential customers. Because of this access, large
            nonprofit memberships are particularly attractive to many companies
            because of years of experience and wide-scale popularity.
          </li>
        </Col>
        <Col xs={12} lg={6}>
          <img src={sol5} alt="" />
        </Col>
      </Row>

      <li className="mt-3">
        Any local charity aims to improve the living and working conditions of
        the population, and therefore any company located there is a potential
        partner. Therefore, the location of the organisation is another aspect
        to be kept in mind. When partners' goals and interests complement each
        other, however, cause-related marketing alliances will be the most
        effective.{" "}
      </li>
      <li className="mt-3">
        Corporations can gain access to people who influence consumer purchases
        through nonprofit organizations. For example, health professionals
        highly regarded nonprofit organizations as dedicated to medical issues.
        Drug companies rely on medical professionals to prescribe their drugs,
        but those intermediaries are often hard to reach. These organizations
        are valuable partners for pharmaceutical companies.
      </li>
      <li className="mt-3">
        When two parties are working together on a cause-related marketing
        alliance, they must have the same vision, similar organizational
        structures and goals. It is most beneficial for large nonprofits working
        on many issues at the local and national levels to partner with
        corporations that can simultaneously run national campaigns and operate
        at the local level utilizing local offices or franchises. On the other
        hand, strictly local organizations should probably stay away from
        corporations with a national or international focus.{" "}
      </li>
      <li className="mt-3" style={{ fontWeight: "800" }}>
        Start with yourself
      </li>

      <li className="mt-3">
        Bringing another person into the mix is like bringing any successful
        relationship to a successful conclusion without knowing yourself first.
        The first step in evaluating right partner is to ask yourself what you
        value as a company:
      </li>
      <li className="mt-3">▪ How does giving back make you feel?</li>
      <li className="mt-3">
        ▪ Together with your nonprofit partner, what are the issues or causes
        you'd like to tackle?
      </li>
      <li className="mt-3">
        ▪ Are you trying to make an impact where you give? If so, what are your
        geographic horizons?
      </li>

      <li className="mt-3">
        ▪ If you could have a perfect partnership, what would it look like? Are
        you more in tune with your partner and collaborative, or are you more
        detached?
      </li>
      <li className="mt-3">
        ▪ Where does corporate giving fit into long-term company goals?
      </li>
      <li className="mt-3">
        ▪ Is there any way you would like your employees to participate in this
        partnership?
      </li>
      <li className="mt-3">
        ▪ What benefits will you gain from this partnership?
      </li>

      <li className="mt-3">
        With this information, you should be able to determine what your company
        is seeking from a nonprofit partner and find the right one more easily.
        It's time to evaluate potential partners after you have familiarized
        yourself with these answers.
      </li>
      <li className="mt-3" style={{ fontWeight: "800" }}>
        Decide which is right for you!
      </li>
      <li className="mt-3">
        There are likely numerous nonprofits that are willing to work with you.
        The key to partnering with a charity is to choose one that will help you
        accomplish your goals and has the potential to produce real change.
        Consider some of the following points in choosing a charity partner:
      </li>
      <li className="mt-3">
        o Is there a tangible impact metric included in their annual report? Are
        their accomplishments demonstrated?
      </li>

      <li className="mt-3">o How are their finances broken down?</li>

      <li className="mt-3">
        o Are you able to determine the percentage of your donation that will be
        used to advance a particular project or impact area with them?
      </li>
      <li className="mt-3">
        o If that's your goal for the partnership, are they open to your
        employees volunteering?
      </li>

      <li className="mt-3">
        Upon answering these questions, you should begin to understand what each
        nonprofit's impact and fit could mean for your business. Although some
        of these questions are more straightforward than others, others may pose
        a greater challenge. Many businesses are unsure how to approach
        discussions about financials or budget breakdowns. Corporations and
        nonprofit organizations combine forces to create a cause-related
        marketing alliance, requiring each to do things that benefit the other
        sharing complete accountability.{" "}
      </li>
      <li className="mt-3" style={{ fontWeight: "800" }}>
        Long-term and profitable partnerships
      </li>

      <li className="mt-3">
        To find long-term partnerships, corporations must identify a nonprofit
        with a good track record, robust finances, a professional staff, and
        preferably, experience as a marketing partner. If a nonprofit does not
        have such credentials, it may be forced to settle for short-term
        alliances.
      </li>
      <li className="mt-3">
        A nonprofit cannot embark on a cause-related marketing alliance with a
        corporation no matter how thoroughly it researches and markets itself.
        Both sides must have open lines of communication with absolute
        transparency. They must be explicit about their expectations and goals
        to negotiate a mutually beneficial agreement. Ideally, the contract
        should spell out all legal documents as well as the project's objectives
        and define how they will be measured; stipulate whether the corporation
        will be the nonprofit's only partner or the only partner from a
        particular industry; layout the resources that each party will commit as
        well as its areas of responsibility.
      </li>
      <li className="mt-3">
        Partner meetings should be held regularly to track project progress, and
        the results should be analyzed as honestly as possible as the program
        gets underway. By focusing on these metrics, the companies improved
        their image, increased customer loyalty, and enhanced employee and
        customer satisfaction. Measurement is difficult in certain cases, such
        as the impact on a company's reputation over the long term.{" "}
      </li>
      <li className="mt-3">
        As well as being open and honest, both parties must communicate with the
        public. Public perception can destroy the effectiveness of many good
        programs if they believe that the nonprofit has been manipulated by the
        company to disguise an inferior product, or that they won't receive any
        funds from the program. It is important to inform the public about
        corporate donations that are not related to consumer actions.
      </li>
      <li className="my-3">
        Alliances between nonprofit organizations can be difficult to manage.
        They can yield enormous benefits to both partners-and so to the public
        as well-when they work together. Both corporations and nonprofits are
        boosting their image and raising vital funds, but both parties are also
        bringing attention to social issues that might otherwise go unnoticed.
        The purpose of cause-related marketing is to market to a cause that
        positively impacts the lives of others. 9th block aims to help
        corporations collaborate with the most suitable non-profits (be it
        domestic or international), which will not only help both of them grow
        but also have a long-term positive impact on the social and
        environmental background.
      </li>
    </div>
  );
}

export default SearchingForRight;
