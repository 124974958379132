import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import pic from "../../img/p1.png";

const WhyUs = () => {
  return (
    <div className="padding-side mt-4">
      <Row className="mx-0 py-5">
        {/* <Col xs={12} lg={3}>
          <img src={pic} alt="" style={{ width: "250px", height: "300px" }} />
        </Col> */}
        <Col xs={12} lg={12} className="text-center">
          <li
            style={{
              fontSize: "1.8rem",
              fontWeight: "700",
            }}
          >
            More than ever before collaboration between Corporates and NGOS is
            important to create ..
          </li>

          <Row className="mx-0 mt-3">
            <Col xs={12} lg={4}>
              <div className="mt-3">
                <li
                  style={{
                    fontSize: "2.2rem",
                    fontWeight: "700",
                    color: "#262a4e",
                  }}
                >
                  93%
                </li>
                <li style={{ color: "#262a4e", textAlign: "start" }}>
                  of employees believe that now, more than ever companies must
                  lead with purpose
                </li>
                <Link to="https://www.porternovelli.com/wp-content/uploads/2021/01/02_Porter-Novelli-Tracker-Wave-X-Employee-Perspectives-on-Responsible-Leadership-During-Crisis.pdf">
                  (Link)
                </Link>
              </div>
            </Col>
            <Col xs={12} lg={4}>
              <div className="mt-3">
                <li
                  style={{
                    fontSize: "2.2rem",
                    fontWeight: "700",
                    color: "#262a4e",
                  }}
                >
                  81%
                </li>
                <li style={{ color: "#262a4e", textAlign: "start" }}>
                  of purpose-driven companies with better ESG profiles
                  outperformed their global counterparts in 2020, despite a
                  market downturn
                </li>
                <Link to="https://www.weforum.org/agenda/2021/09/3-paradigm-shifts-in-corporate-sustainability-to-esg/">
                  (Link)
                </Link>
                <Link to="https://www.blackrock.com/corporate/investor-relations/larry-fink-ceo-letter#:~:text=Over%20the%20course%20of%202020,indexes%20outperformed%20their%20parent%20benchmarks">
                  (Link)
                </Link>
              </div>
            </Col>
            <Col xs={12} lg={4}>
              <div className="mt-3">
                <li
                  style={{
                    fontSize: "2.2rem",
                    fontWeight: "700",
                    color: "#262a4e",
                  }}
                >
                  71%
                </li>
                <li style={{ color: "#262a4e", textAlign: "start" }}>
                  of surveyed employees say it is imperative or very important
                  to work where culture is supportive of giving and
                  volunteering. (Source: America's Charities Snapshot Employee
                  Donor Research)
                </li>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default WhyUs;
