import React from "react";
import { Col, Row } from "react-bootstrap";
import sol3 from "../../img/sol1.png";

function TechForNonProfit() {
  return (
    <div className="mt-5 padding-side">
      <li
        className=" text-center"
        style={{ fontWeight: "800", fontSize: "1.4rem" }}
      >
        Technology & Innovation for Non-Profits
      </li>
      <Row className="mt-4">
        <Col xs={12} lg={6}>
          <li className="mt-3">
            A lot has happened to this world over the last two or so decades,
            but one of the greatest events in technological development.
            Technology which is fundamental to making our lives better and more
            fulfilling has altered the world in a variety of ways. The positive
            influence of technology on globalisation continues to be seen. With
            technology at the centre of any business plan these days, the
            question isn't so much if you have it as when you should acquire it.
            We need to figure out how to make this information more accessible
            and inexpensive for sectors that need it more than ever.{" "}
          </li>
          <li className="mt-3">
            Many nonprofit organizations focus on technology as a means of
            raising awareness of their causes and businesses to make profits.
            For nonprofits, technology is easily viewed as a tool for reaching
            out to new donors via search, social media, or email. The
            infrastructure of technology can be used by some organizations to
            advance their causes.
          </li>
          <li className="mt-3" style={{ fontWeight: "800" }}>
            Technology for Businesses/Organisations (For-Profit & Non-Profit)
          </li>
          <li className="mt-3">
            Organisations are surrounded by oodles and oodles of data. However,
            without the ability to analyze and group "raw" data into categories,
            this data is almost worthless. The raw data that they collect in the
            background could, for example, include data on customer spending
            habits, their preferences, the most popular colour for certain
            products, and so on. By analyzing historical data, it is possible to
            identify trends and predict potential opportunities for the future,
            which is where analytics come in.
          </li>
        </Col>
        <Col xs={12} lg={6}>
          <img src={sol3} alt="" />
        </Col>
      </Row>

      <li className="mt-3">
        The use of software in commercial setups has contributed to more
        efficient operations. Now, such a practice is no longer acceptable as
        software components exist to prevent the unwarranted wastage of raw
        materials. The employee is more productive than ever today thanks to the
        fact that their outputs can be easily tracked nowadays by using the
        numerous types of software tools available on the market. The result is
        an increase in efficiency in the workplace since the practice
        discourages tardiness and instead encourages punctuality among
        employees.
      </li>
      <li className="mt-3">
        One other industry, reliant on software development heavily, is health
        care. Computers and software play an important role in a variety of
        healthcare applications, such as maintaining patient records, diagnosing
        illnesses, and administering treatments. Healthcare would be chaotic if
        it were not for the special software tools intended for medical usage.
        In the absence of modern hospitals, it would be difficult to manage
        patient records, detect diseases, and administer treatment.
      </li>
      <li className="mt-3" style={{ fontWeight: "800" }}>
        The Advent of Artificial Intelligence
      </li>
      <li className="mt-3">
        Our workplaces will become more efficient with AI, and humans will not
        be required to complete repetitive tasks. Artificial Intelligence's
        ability to rapidly complete repetitive tasks frees up time for humans to
        concentrate on creative tasks, which leaves room for them to understand
        and grow interpersonally.
      </li>

      <li className="mt-3">
        ▪ By using Artificial Intelligence, people can do things they have never
        been able to do before, such as email, access a ride-sharing service, or
        connect with friends.
      </li>
      <li className="mt-3">
        {" "}
        ▪ A robot surgeon could be performing operations in the future, which
        would completely change healthcare. Traditional surgeons would just be
        observers. The 3D printing of an artificial heart could lead to the
        development of artificial organs and stem cell therapies.
      </li>
      <li className="mt-3">
        {" "}
        ▪ The use of social robots in healthcare and education can provide
        therapists with additional assistance and promote vocabulary development
        in children.
      </li>
      <li className="mt-3">
        {" "}
        ▪ In the criminal justice system, algorithmic criminal justice could
        help support the rule of law. Thanks to artificial intelligence, we will
        be able to predict crime and make decisions without being influenced by
        human biases and cognitive biases.
      </li>

      <li className="mt-3" style={{ fontWeight: "800" }}>
        How is Technology Advantageous for Non-Profits?
      </li>
      <li className="mt-3">
        Organizations with nonprofit status are often at a disadvantage compared
        to profit-making ones. Nonprofit organisations may run more smoothly and
        cost-effectively by maximising the use of technology. Nonprofit
        technology supports the organisation, its volunteers, workers, and other
        stakeholders.
      </li>

      <li className="mt-3">
        In general, nonprofits strive to reach and assist as many people as
        possible. Technology allows a charity to broaden its reach in terms of
        the products and services it offers, therefore improving its capacity to
        serve its target audience.
      </li>
      <li className="mt-3">
        When done correctly, technology adoption isn't about running after the
        latest gadget; it's about carefully selecting digital tools that enable
        employees and stakeholders to carry out the organization's goal. It's an
        innovative culture that accepts digital, non-digital, and hybrid
        solutions.
      </li>
      <li className="mt-3">
        The nonprofit industry isn't renowned for being particularly
        forward-thinking or flexible. Organizations are focused on their
        purpose, whether it is to feed people, educate people, or assist the
        crippled. They aren't thinking about how to expand their profits or
        bring in new business as a startup or other for-profit firm would.
      </li>

      <li className="mt-3">
        Many people, especially those who are older and less tech-savvy,
        including many nonprofit leaders, are unfamiliar with using technology
        in the sophisticated ways we're considering. However, because the
        mission is all-encompassing, people will jump on board if they can see
        how technology may help them deliver better on their purpose.
      </li>
      <li className="mt-3">
        When it comes to the charity sector, people rarely think about
        competition. They do not believe they are at risk of falling behind.
        Nonprofits are always fighting for funds, volunteers, and skilled
        employees. The most exciting and vibrant enterprises will bring in such
        resources.
      </li>
      <li className="mt-3" style={{ fontWeight: "800" }}>
        Appropriate Use of Tech for Non-Profits
      </li>
      <li className="mt-3">
        The appropriate technology may substantially assist organisations in
        increasing their revenue. Many contributors choose to give via
        electronic means, which eliminates the need for leaders to attend
        meetings to publicly solicit funds.
      </li>
      <li className="mt-3">
        Electronic communication is also preferred by donors, members, and
        stakeholders since it is more convenient and timely on both sides.
        Donors, members, and other stakeholders can learn about a nonprofit's
        success through electronic communication. The website of a nonprofit
        organisation is a high-profile tool for informing and educating
        supporters about the organization's activities.
      </li>

      <li className="mt-3">
        Because the nonprofit sector operates on a shoestring budget, the
        expense of modern technology might appear prohibitive. However,
        efficient use of technology may foster creativity, increased efficacy,
        and improved mission performance, which more than justifies the expense
        and obstacles of organisational transformation.
      </li>
      <li className="mt-3">
        It has been demonstrated that these techniques succeed when used by
        non-profit organisations. However, to achieve long-term goals, these
        must become an inherent part of their organisation, rather than
        something that is contingent on who they work with, how they operate, or
        where they receive their money. We need to look at how we can support
        the cause and the nucleus of the organisation, not simply the
        prevention, with the voice of the individuals giving money for charity
        every day.
      </li>
      <li className="mt-3"></li>

      <li className="my-3">
        9th Block aims to fulfil this gap between technology and non-profits to
        make organizations understand the importance of innovation and
        technology. Progress can only take place once you’re ridden of old,
        comfortable ways and replace them with change. This very change can be
        brought about with technology. Hence, lead to a world where there’s
        equality and fairness amongst all.
      </li>
    </div>
  );
}

export default TechForNonProfit;
