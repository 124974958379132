import React from "react";
import { Col, Row } from "react-bootstrap";
import Header from "../Header";
import pic1 from "../../img/e1.png";
import pic2 from "../../img/e2.png";

const NGO = () => {
  return (
    <div className="header-marg padding-side">
      <Header />
      <div className="text-center py-4">
        <li style={{ fontSize: "1.6rem", fontWeight: "800" }}>NGO's</li>
        <li className="mt-1">
          We make the matching and vetting process more accountable, helping
          NGOs expand their short and long-term funding needs, reach relevant
          stakeholders, showcase their impact and engage with the community.
        </li>
      </div>

      <Row className="mx-0 align-items-center">
        <Col xs={12} lg={5} className="mt-5">
          <div>
            <img
              src={pic1}
              alt=""
              style={{
                borderRadius: "50%",
                boxShadow: "0 0 15px 10px rgba(0,0,0,0.4)",
              }}
            />
          </div>
        </Col>
        <Col xs={12} lg={7} className="mt-5">
          <div>
            <li style={{ fontSize: "1.4rem", fontWeight: "600" }}>
              Fundraising & Donations
            </li>
            <li className="mt-2">
              Raise funds and achieve social impact goals by matching your
              organization’s program project needs with stakeholders.
            </li>
            <li className="mt-2">
              Showcase your impact stories with democratized fundraising
              opportunities, advertise your needs both big and small, and
              arrange resources (like training materials) to fulfill your
              beneficiaries’ requests more quickly and efficiently through a
              transparent search process.
            </li>
          </div>
        </Col>
      </Row>

      <Row className="mx-0 align-items-center">
        <Col xs={12} lg={7} className="mt-5">
          <div>
            <li style={{ fontSize: "1.4rem", fontWeight: "600" }}>
              Collaboration Management
            </li>
            <li className="mt-2">
              Get better transparency and efficiency with automated tools for
              collaboration and consulting support for program execution.
            </li>
            <li className="mt-2">
              Improve transparency and productivity in the CSR ecosystem through
              our solutions. Leverage technology and build a socially
              responsible community through deep and engaging connections with
              stakeholders. Extend reach to wider audiences and mobilize
              volunteers for your cause.
            </li>
          </div>
        </Col>
        <Col xs={12} lg={5} className="mt-5">
          <div>
            <img
              src={pic2}
              alt=""
              style={{
                borderRadius: "50%",
                boxShadow: "0 0 15px 10px rgba(0,0,0,0.4)",
              }}
            />
          </div>
        </Col>
      </Row>

      <Row className="mx-0 align-items-center">
        <Col xs={12} lg={5} className="mt-5">
          <div>
            <img
              src={pic1}
              alt=""
              style={{
                borderRadius: "50%",
                boxShadow: "0 0 15px 10px rgba(0,0,0,0.4)",
              }}
            />
          </div>
        </Col>
        <Col xs={12} lg={7} className="mt-5">
          <div>
            <li style={{ fontSize: "1.4rem", fontWeight: "600" }}>
              Impact Assessment & Reporting
            </li>
            <li className="mt-2">
              Capture qualitative and quantitative data for customized reports
              and improved accountability, fostering valuable stakeholder
              associations.
            </li>
            <li className="mt-2">
              Attract beneficial collaborations and raise funds from
              non-traditional sources through customized compliance reports.
              Enable strategic planning, collect qualitative and quantitative
              data, improve accountability and build credibility via automated
              processes and data to manage multiple donors and convey impactful
              beneficiary stories from start to end – all in one place.
            </li>
          </div>
        </Col>
      </Row>

      <Row className="mx-0 align-items-center">
        <Col xs={12} lg={7} className="mt-5">
          <div>
            <li style={{ fontSize: "1.4rem", fontWeight: "600" }}>
              Capacity Building
            </li>
            <li className="mt-2">
              Build process and resource capacities for powerful impact, aligned
              with your CSR vision for social good.
            </li>
            <li className="mt-2">
              Train staff and volunteers with resources for powerful impact,
              implementing community projects that are in line with your
              mission. We offer you the space and support for sustaining as well
              as scaling operations, improving your capabilities and enabling
              you to contribute to beneficiaries effectively through training,
              skill building and knowledge management.
            </li>
          </div>
        </Col>
        <Col xs={12} lg={5} className="mt-5">
          <div>
            <img
              src={pic2}
              alt=""
              style={{
                borderRadius: "50%",
                boxShadow: "0 0 15px 10px rgba(0,0,0,0.4)",
              }}
            />
          </div>
        </Col>
      </Row>

      <Row className="mx-0 align-items-center">
        <Col xs={12} lg={5} className="my-4">
          <div>
            <img
              src={pic1}
              alt=""
              style={{
                borderRadius: "50%",
                boxShadow: "0 0 15px 10px rgba(0,0,0,0.4)",
              }}
            />
          </div>
        </Col>
        <Col xs={12} lg={7} className="my-4">
          <div>
            <li style={{ fontSize: "1.4rem", fontWeight: "600" }}>
              Digital Solutions
            </li>
            <li className="mt-2">
              Reap the benefits of technology at your fingertips with digital,
              agile and scalable systems
            </li>
            <li className="mt-2">
              Transform into a future-forward organization with customized,
              proprietary solutions and digital branding resources at your
              fingertips. Our agile and scalable platform gives you the tools to
              expand your reach, engage supporters, build your brand, automate
              processes and handle coordination seamlessly.
            </li>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default NGO;
