import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import "aos/dist/aos.css";
import Contact from "./components/contact/Contact";
import Home from "./components/home/Home";
import Resource from "./components/resource/Resource";
import Solution from "./components/solution/Solution";
import Event from "./components/event/Event";
import Footer from "./components/Footer";
import BlogPost from "./components/resource/BlogPost";
import WebinarPost from "./components/event/WebinarPost";
import DigitalVol from "./components/solution/DigitalVol";
import CsrInvestment from "./components/solution/CsrInvestment";
import TechForNonProfit from "./components/solution/TechForNonProfit";
import SearchingForRight from "./components/solution/SearchingForRight";
import NonProfitFundraising from "./components/solution/NonProfitFundraising";
import Blog from "./components/resource/Blog";
import PartnershipMob from "./components/resource/PartnershipMob";
import UpcomingEvent from "./components/event/UpcomingEvent";
import Webinar from "./components/event/Webinar";
import Landingpage from "./components/Landingpage";
import Edge from "./components/product/Edge";
import Hub from "./components/product/Hub";
import Demo from "./components/demo/Demo";
import NGO from "./components/solution/NGO";
import Donor from "./components/solution/Donor";
import EdgeNew from "./components/product/EdgeNew";
import HubNew from "./components/product/HubNew";
import About from "./components/about/About";
import Career from "./components/career/Career";

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          {/* <Route path="/landingpage" exact component={Landingpage} /> */}
          <Route path="/" exact component={Home} />
          <Route path="/edge" component={EdgeNew} />
          <Route path="/hub" component={HubNew} />
          <Route path="/ngo" component={NGO} />
          <Route path="/donor" component={Donor} />
          <Route path="/solution" exact component={Solution} />
          <Route path="/resource" exact component={Resource} />
          <Route path="/contact" component={Contact} />
          <Route path="/career" component={Career} />
          <Route path="/about" component={About} />
          <Route path="/event" exact component={Event} />
          <Route path="/resource/blog" component={BlogPost} />
          <Route path="/event/webinar" component={WebinarPost} />
          <Route
            path="/solution/digital-volunteer"
            exact
            component={DigitalVol}
          />
          <Route
            path="/solution/csr-investment"
            exact
            component={CsrInvestment}
          />
          <Route
            path="/solution/tech-for-non-profit"
            exact
            component={TechForNonProfit}
          />
          <Route
            path="/non-profit-fundraising"
            exact
            component={NonProfitFundraising}
          />
          <Route
            path="/solution/searching-for-right-non-profit"
            exact
            component={SearchingForRight}
          />
          <Route path="/partnership" component={PartnershipMob} />
          <Route path="/blog" component={Blog} />
          <Route path="/upcoming-events" component={UpcomingEvent} />
          <Route path="/webinar" component={Webinar} />
          <Route path="/book-a-demo" component={Demo} />
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
