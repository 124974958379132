import React from "react";
import { Col, Row } from "react-bootstrap";
import blog from "../../img/webinar.png";
import e1 from "../../img/e1.png";
import e2 from "../../img/e2.png";
import { NavLink } from "react-router-dom";

function WebinarPost() {
  return (
    <div className="header-marg ">
      <div className="blog-bg padding-side pt-5">
        <li className="link-name">Education</li>
        <li style={{ fontSize: "1.8rem", fontWeight: "700" }}>Topic name</li>
        <img
          src={blog}
          className="blog-post-ht"
          style={{ width: "100%", marginTop: "1rem" }}
          alt=""
        />
      </div>

      <Row className="mx-0 padding-side blog-post-top">
        <Col xs={12} lg={3} className="only-web">
          <div>
            <li className="posted">Organised by:</li>
            <li>Together</li>
            <li className="posted mt-3">Duration</li>
            <li>July 22, 2021</li>
            <NavLink to="/contact">
              <button className="btn-start my-4">Register</button>
            </NavLink>
          </div>
        </Col>

        <Col xs={12} lg={3} className="only-mob my-3">
          <div className="d-flex justify-content-between">
            <li className="posted">Organised by:</li>
            <li className="posted ">Duration</li>
          </div>
          <div className="d-flex justify-content-between">
            <li>Together</li>
            <li>July 22, 2021</li>
          </div>
          <NavLink to="/contact">
            <button className="btn-start my-4 w-100">Register</button>
          </NavLink>
        </Col>

        <Col xs={12} lg={9}>
          <div>
            <li className="webinar-cont"> The Context</li>
            <li className="mt-2">
              The Companies Act 2013, has shifted the spotlight from corporate
              as givers and NGOs as receivers to building a picture of a
              partnership. This relationship of parity calls for a change in the
              mindset of both the corporate and the NGO with the onus being on
              the NGOs to be proactive and positive in building an association
              with the corporate. NGOs can now take the lead as a representative
              of the civil society and play the role of a strategic partner in
              tri-party relationships between the government, civil society, and
              commercial business houses. It’s the most opportune time for the
              NGOs to take up leadership roles and establish new norms and
              models for Corporate-NGO partnership.
            </li>

            <li className="webinar-cont mt-5">The Masterclass</li>
            <li className="mt-2">
              The 2 days (6 Hrs.) online masterclass is designed to give
              insights to participants (including NGO, social businesses, CSR
              Leaders) into the hidden opportunities for the NGOs in the current
              business environment, understand the mutual benefits of
              partnership, and discuss the challenges which may be encountered
              in the process.
            </li>
            <li>
              The masterclass will acquaint the participants with a ring-side
              view of what companies look for in selecting a partner NGO. The
              hands-on sessions will build competency in communicating the
              essential qualities to the corporate as well as making these
              qualities a visible part of the NGO’s public image. Participants
              will learn about story-telling for NGO and social business brand
              building.
            </li>

            <li className="webinar-cont mt-5">Key Insights</li>
            <li className="mt-2">
              Learning from Experiences: A blend of corporate, NGO, and
              stakeholder views shared by academicians and trainers.
            </li>
            <li>
              Learning from Research and Current Trends in CSR: Insights from
              trainers who have had the first-hand experience of working,
              training, and researching in the same domain.
            </li>
            <li>
              Brainstorming Strategies: Interactive session driven by the
              participants and moderated by the facilitators to ensure active
              engagement. The situation/case-based pedagogy will help exemplify
              the theoretical concepts detailed and discussed by the
              facilitators.
            </li>

            <li className="webinar-cont mt-5">For Whom</li>
            <li className="mt-2">
              NGO professionals handling CSR partnerships, communication, and
              social development projects
            </li>
            <li>Social business founders and communication team members</li>
            <li>
              Post-graduate students pursuing rural management, social work,
              development communication, and social entrepreneurship courses
            </li>

            <li className="webinar-cont mt-5">Pedagogy</li>
            <li className="mt-2">
              Active learner-oriented pedagogy of business cases and situation
              analysis clubbed with discussion about the CSR, communication and
              Corporate-NGO partnership concepts and models.
            </li>

            <li className="webinar-cont mt-5">Certificate of Participation</li>
            <li className="mt-2">
              Participants will receive an e-certificate from CDMC- MICA and
              Impact Academy for their participation and completion of 6 hrs. of
              the masterclass.
            </li>

            <li className="webinar-cont mt-5">Fee</li>
            <li className="mt-2">
              INR 4,000 (Early Bird till 22nd July, 2021)
            </li>
            <li>INR 5,000 (23rd July 2021 onwards)</li>
            <li>
              Registration Deadline: 11th August 2021 (subject to availability
              of seats)
            </li>

            <li className="webinar-cont mt-5">Contact</li>
            <li className="mt-2">Somya Trivedi</li>
            <li>somya@csrbox.org</li>
            <li>Mobile: 8130050215</li>

            <li className="webinar-cont mt-5">Terms and Conditions</li>
            <li className="mt-2">
              Registration fee is non-refundable, however nominee name can be
              changed
            </li>
            <li>
              Once you submit the registration form along with the payment
              (online), you will receive the confirmation email within 2 working
              days. You will receive the details to access the online
              masterclass along with a demo of how to use the platform latest by
              12th August, 2021.
            </li>
            <li>The recordings of the session will not be available.</li>
          </div>
          <hr />

          <div className="res-card my-5">
            <Row>
              <Col>
                <img src={e1} height="100%" alt="" />
              </Col>
              <Col>
                <div className="p-4">
                  <li className="text-end">5 min. read</li>
                  <li className="link-name">PEOPLE</li>
                  <li className="benefit-title mt-1">Topic name</li>
                  <li className="mt-2">
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    Totam nam, sit quod unde expedita, maxime quis sapiente et
                    beatae impedit, libero dolor fugit iste consequatur neque
                    porro accusamus harum vero.
                  </li>
                  <li className="sol-read mt-3">Watch Now..</li>
                </div>
              </Col>
            </Row>
          </div>
          <div className="res-card my-4">
            <Row>
              <Col>
                <img src={e2} height="100%" alt="" />
              </Col>
              <Col>
                <div className="p-4">
                  <li className="text-end">5 min. read</li>
                  <li className="link-name">PEOPLE</li>
                  <li className="benefit-title mt-1">Topic name</li>
                  <li className="mt-2">
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    Totam nam, sit quod unde expedita, maxime quis sapiente et
                    beatae impedit, libero dolor fugit iste consequatur neque
                    porro accusamus harum vero.
                  </li>
                  <li className="sol-read mt-3">Watch Now..</li>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default WebinarPost;
