import React from "react";
import { Link } from "react-router-dom";
import Header from "../Header";
import { Facebook, Instagram, LinkedIn } from "@material-ui/icons";

const Career = () => {
  return (
    <div className="header-marg">
      <Header />
      <div className="padding-side py-4">
        <li
          style={{
            fontSize: "1.6rem",
            fontWeight: "700",
            textAlign: "center",
          }}
        >
          Join Our Team
        </li>
        <li className="mt-2 text-center">
          Fuel social change with purposeful ideas and technology
        </li>
        <li className="mt-2 text-center">
          We're looking for motivated people passionate about facilitating
          social impact and helping communities.
        </li>
        <li className="mt-2 text-center">
          If you're one of them, reach out to us!
        </li>
      </div>

      <div className="padding-side py-4">
        <li
          style={{
            fontSize: "1.6rem",
            fontWeight: "700",
            textAlign: "center",
          }}
        >
          Get In Touch
        </li>
        <li className="mt-2 text-center">Our team is here for your needs</li>
        <li className="mt-2 text-center">
          If you're part of the social impact ecosystem, we can help bring your
          social impact vision to life.
        </li>
        <div className="text-center">
          <Link to="/">
            <button className="btn-start px-3 mt-4 py-1 mx-3">
              Get in touch
            </button>
          </Link>
          <Link to="/">
            <button className="btn-start px-3 mt-4 py-1 mx-3">
              Book a demo
            </button>
          </Link>
        </div>
      </div>

      <div className="padding-side py-4">
        <li
          style={{
            fontSize: "1.6rem",
            fontWeight: "700",
            textAlign: "center",
          }}
        >
          Connect with us
        </li>
        <div className="text-center mt-2">
          <a href="https://www.facebook.com/9thblock-103229902139875">
            <Facebook style={{ color: "#a1a1a1" }} />
          </a>
          <a href="https://www.instagram.com/9thblockinc/">
            <Instagram style={{ margin: "0 20px", color: "#a1a1a1" }} />
          </a>
          <a href="https://www.linkedin.com/company/9thblock-inc">
            <LinkedIn style={{ color: "#a1a1a1" }} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Career;
