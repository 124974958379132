import React from "react";
import { Col, Row } from "react-bootstrap";
import edge1 from "../../img/laptop.png";
import edge2 from "../../img/mobile1.png";
import hub1 from "../../img/mobile.png";
import prod from "../../img/service.png";
import { Link } from "react-router-dom";

const Products = () => {
  return (
    <div className="padding-side my-5">
      <Row className="mx-0" style={{ alignItems: "center" }}>
        <Col xs={12} lg={6} className="mt-4">
          <div>
            <img src={prod} alt="" style={{ width: "80%", height: "80%" }} />
          </div>
        </Col>
        <Col xs={12} lg={6} className="mt-4">
          <div>
            <li
              style={{
                fontSize: "1.8rem",
                fontWeight: "700",
              }}
            >
              Drive meaningful impact with collaboration
            </li>
            <li className="mt-2" style={{ fontSize: "1.08rem" }}>
              9th Block's community engagement platforms fuels collaboration for
              all social responsibility stakeholders
            </li>
            <div className="mb-4">
              <Link to="/">
                <button className="btn-start px-3 mt-4 py-2">
                  Book a Demo
                </button>
              </Link>
              <Link to="/">
                <button
                  className="btn-cancel px-3 mt-4 py-2"
                  style={{ marginLeft: "1rem" }}
                >
                  Learn more
                </button>
              </Link>
            </div>
          </div>
        </Col>

        <Col xs={12} lg={6} className="mt-4">
          <div>
            <li
              style={{
                fontSize: "1.8rem",
                fontWeight: "700",
              }}
            >
              Edge
            </li>
            <li className="mt-2" style={{ fontSize: "1.08rem" }}>
              Drive collaboration through communication with all stakeholders in
              one place.
            </li>
            <div className="mb-4">
              <Link to="/">
                <button className="btn-start px-3 mt-4 py-2">Edge</button>
              </Link>
              <Link to="/">
                <button
                  className="btn-start px-3 mt-4 py-2"
                  style={{ marginLeft: "1rem" }}
                >
                  Book a Demo
                </button>
              </Link>
            </div>
          </div>
        </Col>
        <Col xs={12} lg={6} className="mt-4">
          <div>
            <img src={edge1} alt="" style={{ width: "80%", height: "80%" }} />
          </div>
        </Col>

        <Col xs={12} lg={6} className="mt-4">
          <div>
            <img src={hub1} alt="" style={{ width: "80%", height: "80%" }} />
          </div>
        </Col>
        <Col xs={12} lg={6} className="mt-4">
          <div>
            <li
              style={{
                fontSize: "1.8rem",
                fontWeight: "700",
              }}
            >
              Hub
            </li>
            <li className="mt-2" style={{ fontSize: "1.08rem" }}>
              Network, match needs, and collaborate with social impact
              stakeholders seamlessly, all in one place.
            </li>
            <div className="mb-4">
              <Link to="/">
                <button className="btn-start px-3 mt-4 py-2">Hub</button>
              </Link>
              <Link to="/">
                <button
                  className="btn-start px-3 mt-4 py-2"
                  style={{ marginLeft: "1rem" }}
                >
                  Book a Demo
                </button>
              </Link>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Products;
