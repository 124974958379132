import React from "react";
import { Col, Row } from "react-bootstrap";
import up1 from "../../img/up1.png";
import up2 from "../../img/up2.png";
import up3 from "../../img/up3.png";

function HomeEvent() {
  return (
    <>
      <div
        className="padding-side mt-5"
        style={{ backgroundColor: "#F9FCFE", width: "100%", height: "100vh" }}
      >
        <li className="home-problem-title text-center pt-4">Upcoming Events</li>

        <Row className="mx-0 mt-5" style={{ height: "100%" }}>
          <Col xs={12} lg={4}>
            <div className="upcoming-card">
              <img src={up1} className="upcoming-card-img" alt="" />
              <li
                className="upcoming-card-2"
                style={{ backgroundColor: "#70ECA9" }}
              >
                Podcast
              </li>
              <li className="upcoming-card-title mt-4 mb-2">
                Virtual Masterclass
              </li>
              <li className="px-2" style={{ color: "#053F5A" }}>
                Strategic Communication and Partnership Building for Social
                Impact
              </li>
              <li
                className="mt-2"
                style={{ color: "#053F5A", fontWeight: "600" }}
              >
                11 Feb,22
              </li>
            </div>
          </Col>
          <Col xs={12} lg={4}>
            <div className="upcoming-card">
              <img src={up2} className="upcoming-card-img" alt="" />
              <li
                className="upcoming-card-2"
                style={{ backgroundColor: "#9C90E4" }}
              >
                Webinar
              </li>
              <li className="upcoming-card-title mt-4 mb-2">
                Virtual Masterclass
              </li>
              <li className="px-2" style={{ color: "#053F5A" }}>
                Strategic Communication and Partnership Building for Social
                Impact
              </li>
              <li
                className="mt-2"
                style={{ color: "#053F5A", fontWeight: "600" }}
              >
                11 Feb,22
              </li>
            </div>
          </Col>
          <Col xs={12} lg={4}>
            <div className="upcoming-card">
              <img src={up1} className="upcoming-card-img" alt="" />
              <li
                className="upcoming-card-2"
                style={{ backgroundColor: "#8AADFF" }}
              >
                Blogs
              </li>
              <li className="upcoming-card-title mt-4 mb-2">
                Virtual Masterclass
              </li>
              <li className="px-2" style={{ color: "#053F5A" }}>
                Strategic Communication and Partnership Building for Social
                Impact
              </li>
              <li
                className="mt-2"
                style={{ color: "#053F5A", fontWeight: "600" }}
              >
                11 Feb,22
              </li>
            </div>
          </Col>
        </Row>
      </div>

      {/* <div
          className="home-event-card mt-2 only-mob"
          style={{ height: "650px" }}
        >
          <Row className="mx-0 " style={{ height: "100%" }}>
            <Col md={12} className="px-0">
              <div className="">
                <li className="home-event-up podcast-color px-4">
                  Upcoming podcast
                </li>
                <li className="home-event-cont  px-3 py-2 mt-2 text-start">
                  Fundraising
                </li>
                <li className="home-event-cont2  px-3 py-2 text-start">
                  Guest Speaker : Jason Medlycott Head of Fundraising &
                  Communication Manager from Pardada Pardadi Educational Society
                </li>

                <li className="home-event-cont3  px-3 py-2 text-start">
                  Coming soon
                </li>
              </div>
            </Col>
            <Col md={12} className="px-0">
              <div className="home-event-bg text-center">
                <a href="/contact">
                  <button
                    className="btn-start"
                    style={{
                      marginTop: "190px",
                      padding: "10px 40px",
                    }}
                  >
                    Know more
                  </button>
                </a>
              </div>
            </Col>
          </Row>
        </div> */}

      {/* <div className="home-more more">
        <div className="more-colr" style={{ height: "50vh" }}>
          <NavLink to="/contact">
            <button className="btn-start" style={{ padding: "20px 40px" }}>
              Make social impact
            </button>
          </NavLink>
        </div>
      </div> */}
    </>
  );
}

export default HomeEvent;
