import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import benifitEffect from "../../img/benefit-effect.png";
import benifitEffect2 from "../../img/benefit-effect2.png";
import b1 from "../../img/b11.png";
import b2 from "../../img/b12.png";
import b3 from "../../img/b13.png";
import youtube from "../../img/youtube.png";
import { Link } from "react-router-dom";

function Benefit() {
  return (
    <>
      <div
        className="my-5 "
        style={{ backgroundColor: "rgba(203, 214, 226, 0.5)" }}
      >
        {/* <img src={benifitEffect} className="benefit-effect" alt="" />
      <img src={benifitEffect2} className="benefit-effect2 only-web" alt="" /> */}
        {/* <Row className="mx-0 padding-side">
        <li className="benefit text-center mb-5">Benefits</li>

        <Col xs={12} lg={4}>
          <div className="text-center">
            <img src={b1} className="benefit-img" alt="" />
            <li className="benefit-title my-2">Build Credibility</li>
            <li style={{ maxWidth: "300px", margin: "auto" }}>
              Showcase data driven impact stories for effective fundraising and
              building credibility.
            </li>
          </div>
        </Col>
        <Col xs={12} lg={4}>
          <div className="text-center">
            <img src={b2} className="benefit-img" alt="" />
            <li className="benefit-title my-2">
              Continuous and Real-time tracking
            </li>
            <li style={{ maxWidth: "300px", margin: "auto" }}>
              Automate your processes and data collection for efficiency. In one
              click share the progress with all stakeholders.Engage your
              audience
            </li>
          </div>
        </Col>
        <Col xs={12} lg={4}>
          <div className="text-center">
            <img src={b3} className="benefit-img" alt="" />
            <li className="benefit-title my-2">Engaging</li>
            <li style={{ maxWidth: "300px", margin: "auto" }}>
              Engaging the volunteers virtually from anywhere in the world.
            </li>
          </div>
        </Col>
      </Row> */}

        <div className="py-5  padding-side text-center">
          <li
            style={{
              fontWeight: "600",
              textTransform: "uppercase",
              fontSize: "0.85rem",
            }}
          >
            Why us
          </li>
          <li className="" style={{ fontSize: "2.4rem", fontWeight: "800" }}>
            Social & Sustainable Impact Initiatives aren’t Linear anymore
          </li>
          <li className=" mt-2" style={{ fontSize: "1.2rem" }}>
            They need collaborative and transparent efforts from all
            stakeholders – companies, employees, NGOs, the government,
            volunteers, investors and more.
          </li>
          {/* <Link>
          <button className="btn-start px-3 mt-4">
            Explore our Solutions for Seamless Execution
          </button>
        </Link> */}
          <Link>
            <button className="btn-start px-3 mt-4 py-2">
              Explore how 9th Block can be your partner
            </button>
          </Link>

          {/* <li className="" style={{ fontSize: "2.4rem", fontWeight: "800" }}>
          Driving business growth with purpose is a pressing priority
        </li>
        <li className=" mt-2" style={{ fontSize: "1.2rem" }}>
          53% of revenues of the 500 largest US companies and 49% of revenues of
          the 1,200 largest global companies come from business activities that
          support SDGs*
        </li> */}
        </div>
      </div>
    </>
  );
}

export default Benefit;

function Old() {
  return (
    <>
      {/* <Col xs={12} lg={6}>
          <div className="" style={{ marginTop: "150px" }}>
            <li className="benefit mb-5 only-mob">Benefits</li>
            <div style={{ maxWidth: "350px", margin: "0 auto" }}>
              <li className="benefit-title">Build Credibility</li>
              <li className="mt-2 mb-3">
                Showcase data driven impact stories for effective fundraising
                and building credibility.
              </li>
            </div>
          </div>
        </Col>
        <Col xs={12} lg={6}>
          <div className="text-center">
            <li className="benefit mb-5 only-web">Benefits</li>
            <div className="benefit-img b1"></div>
            <img src={b1} className="benefit-img" alt="" />
          </div>
        </Col>

        <Col xs={12} lg={6}>
          <div className="mt-5">
            <div className="benefit-img b2 only-web"></div>
            <img src={b2} className="benefit-img" alt="" />
          </div>
        </Col>
        <Col xs={12} lg={6} className="mt-5">
          <div>
            <img
              src={benifitEffect2}
              className="benefit-effect2 only-web"
              alt=""
            />
            <div style={{ maxWidth: "350px", margin: "0 auto" }}>
              <li className="benefit-title">
                Continuous and Real-time tracking
              </li>
              <li className="mt-2 mb-3">
                Automate processes and real-time data collection so you can
                focus on strategic development projects.
              </li>
            </div>
            <div className="benefit-img b2 only-mob"></div>
          </div>
        </Col>

        <Col xs={12} lg={6} className="mt-5">
          <div style={{ maxWidth: "350px", margin: "0 auto" }}>
            <li className="benefit-title">Engaging</li>
            <li className="mt-2 mb-3">
              Engaging the volunteers virtually from anywhere in the world.
            </li>
          </div>
        </Col>
        <Col>
          <div className="text-center ">
            <img src={b3} className="benefit-img" alt="" />
            <div className="benefit-img b3"></div>
          </div>
        </Col> */}
    </>
  );
}
