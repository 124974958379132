import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "../img/logo.png";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles } from "@material-ui/core/styles";
import { Facebook, Instagram, LinkedIn, Menu } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import Menus from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles({
  list: {
    width: 300,
  },
  fullList: {
    width: "auto",
  },
});

function Header() {
  const [isHistory, setHistory] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [anchorEl3, setAnchorEl3] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClick3 = (event) => {
    setAnchorEl3(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const handleClose3 = () => {
    setAnchorEl3(null);
  };
  const history = useHistory();

  useEffect(() => {
    setHistory(history.location.pathname);
  }, []);

  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={
        (classes.list,
        {
          [classes.fullList]: anchor === "top" || anchor === "bottom",
        })
      }
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List className="menu-list-mob ">
        <ListItem
          component={NavLink}
          activeStyle={{ color: "#FE8C2F", padding: "12px 0" }}
          to="/edge"
          style={{ padding: "12px 0" }}
        >
          <li>EDGE</li>
        </ListItem>
        <ListItem
          component={NavLink}
          activeStyle={{ color: "#FE8C2F", padding: "12px 0" }}
          to="/hub"
          style={{ padding: "12px 0" }}
        >
          <li>HUB</li>
        </ListItem>
        <ListItem
          component={NavLink}
          activeStyle={{ color: "#FE8C2F", padding: "12px 0" }}
          to="/solution"
          style={{ padding: "12px 0" }}
        >
          <li>SOLUTIONS</li>
        </ListItem>
        <ListItem
          component={NavLink}
          activeStyle={{ color: "#FE8C2F", padding: "12px 0" }}
          to="/partnership"
          style={{ padding: "12px 0" }}
        >
          <li>PARTNERSHIP PROGRAM</li>
        </ListItem>
        <ListItem
          component={NavLink}
          activeStyle={{ color: "#FE8C2F", padding: "12px 0" }}
          to="/blog"
          style={{ padding: "12px 0" }}
        >
          <li>BLOG</li>
        </ListItem>
        <ListItem
          component={NavLink}
          activeStyle={{ color: "#FE8C2F", padding: "12px 0" }}
          to="/upcoming-events"
          style={{ padding: "12px 0" }}
        >
          <li>UPCOMING EVENTS</li>
        </ListItem>
        {/* <ListItem
          component={NavLink}
          activeStyle={{ color: "#FE8C2F", padding: "12px 0" }}
          to="/webinar"
          style={{ padding: "12px 0" }}
        >
          <li>WEBINAR</li>
        </ListItem> */}
        <ListItem
          component={NavLink}
          activeStyle={{ color: "#FE8C2F", padding: "12px 0" }}
          to="/contact"
          style={{ padding: "12px 0" }}
        >
          <li>CONTACT US</li>
        </ListItem>
        <ListItem
          component={NavLink}
          activeStyle={{ color: "#FE8C2F", padding: "12px 0" }}
          to="/book-a-demo"
          style={{ padding: "12px 0" }}
        >
          <li>BOOK A DEMO</li>
        </ListItem>
        <div style={{ display: "flex", marginTop: "10px" }}>
          <ListItem
            component={NavLink}
            to="https://www.facebook.com/9thblock-103229902139875"
            style={{ padding: "12px 0", color: "#000" }}
          >
            <Facebook />
          </ListItem>
          <ListItem
            component={NavLink}
            to="https://www.instagram.com/9thblockinc/"
            style={{ padding: "12px 0", color: "#000" }}
          >
            <Instagram />
          </ListItem>
          <ListItem
            component={NavLink}
            to="https://www.linkedin.com/company/9thblock-inc"
            style={{ padding: "12px 0", color: "#000" }}
          >
            <LinkedIn />
          </ListItem>
          {/* <ListItem
            component={NavLink}
            to="/"
            style={{ padding: "12px 0", color: "#000" }}
          >
            <Reddit />
          </ListItem> */}
        </div>
      </List>
    </div>
  );

  return (
    <div className="header">
      <div className="header-flex">
        <div className="only-mob">
          {["left"].map((anchor) => (
            <React.Fragment key={anchor}>
              <div
                style={{ margin: "0 12px 0 4px" }}
                onClick={toggleDrawer(anchor, true)}
              >
                <Menu
                  style={{
                    position: "fixed",
                    left: "15px",
                    marginTop: "-10px",
                  }}
                />
              </div>
              <SwipeableDrawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
                onOpen={toggleDrawer(anchor, true)}
              >
                {list(anchor)}
              </SwipeableDrawer>
            </React.Fragment>
          ))}
        </div>
        <div className="logo-center">
          <a href="/">
            <img src={logo} className="logo" alt="" />
          </a>
        </div>
        <div className="link-a header-lg">
          <li
            className="link-name"
            onClick={handleClick}
            style={
              isHistory === "/edge" || isHistory === "/hub"
                ? { color: "#ED6F2D" }
                : { color: "#000" }
            }
          >
            Products
          </li>
          <Menus
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            style={{ top: "20px" }}
          >
            <a href="/edge">
              <MenuItem
                onClick={handleClose}
                style={
                  isHistory === "/edge"
                    ? { color: "#ED6F2D" }
                    : { color: "#000" }
                }
              >
                Edge
              </MenuItem>
            </a>
            <a href="/hub">
              <MenuItem
                onClick={handleClose}
                style={
                  isHistory === "/hub"
                    ? { color: "#ED6F2D" }
                    : { color: "#000" }
                }
              >
                Hub
              </MenuItem>
            </a>
          </Menus>
          {/* <a href="/product">
            <li
              className="link-name"
              style={
                isHistory === "/product"
                  ? { color: "#ED6F2D" }
                  : { color: "#000" }
              }
            >
              Products
            </li>
          </a> */}
          <li
            className="link-name"
            onClick={handleClick2}
            style={
              isHistory === "/ngo" || isHistory === "/donor"
                ? { color: "#ED6F2D", marginLeft: "1rem" }
                : { color: "#000", marginLeft: "1rem" }
            }
          >
            Solutions
          </li>
          <Menus
            id="simple-menu"
            anchorEl={anchorEl2}
            keepMounted
            open={Boolean(anchorEl2)}
            onClose={handleClose2}
            style={{ top: "20px" }}
          >
            <a href="/ngo">
              <MenuItem
                onClick={handleClose2}
                style={
                  isHistory === "/ngo"
                    ? { color: "#ED6F2D" }
                    : { color: "#000" }
                }
              >
                NGO’s
              </MenuItem>
            </a>
            <a href="/donor">
              <MenuItem
                onClick={handleClose2}
                style={
                  isHistory === "/donor"
                    ? { color: "#ED6F2D" }
                    : { color: "#000" }
                }
              >
                Donors
              </MenuItem>
            </a>
          </Menus>
          {/* <a href="/solution">
            <li
              className="link-name"
              style={
                isHistory === "/solution"
                  ? { color: "#ED6F2D" }
                  : { color: "#000" }
              }
            >
              Solutions
            </li>
          </a> */}
          {/* <a href="/about">
            <li
              className="link-name"
              style={
                isHistory === "/about"
                  ? { color: "#ED6F2D" }
                  : { color: "#000" }
              }
            >
              About Us
            </li>
          </a> */}
          <a href="/resource">
            <li
              className="link-name"
              style={
                isHistory === "/resource"
                  ? { color: "#ED6F2D" }
                  : { color: "#000" }
              }
            >
              Resources
            </li>
          </a>
          <a href="/event">
            <li
              className="link-name"
              style={
                isHistory === "/event"
                  ? { color: "#ED6F2D" }
                  : { color: "#000" }
              }
            >
              Events
            </li>
          </a>

          <li
            className="link-name"
            onClick={handleClick3}
            style={
              isHistory === "/about" ||
              isHistory === "/contact" ||
              isHistory === "/career"
                ? { color: "#ED6F2D", marginLeft: "1rem" }
                : { color: "#000", marginLeft: "1rem" }
            }
          >
            Company
          </li>
          <Menus
            id="simple-menu"
            anchorEl={anchorEl3}
            keepMounted
            open={Boolean(anchorEl3)}
            onClose={handleClose3}
            style={{ top: "20px" }}
          >
            <a href="/about">
              <MenuItem
                onClick={handleClose3}
                style={
                  isHistory === "/about"
                    ? { color: "#ED6F2D" }
                    : { color: "#000" }
                }
              >
                About us
              </MenuItem>
            </a>
            <a href="/contact">
              <MenuItem
                onClick={handleClose3}
                style={
                  isHistory === "/contact"
                    ? { color: "#ED6F2D" }
                    : { color: "#000" }
                }
              >
                Contact us
              </MenuItem>
            </a>
            <a href="/career">
              <MenuItem
                onClick={handleClose3}
                style={
                  isHistory === "/career"
                    ? { color: "#ED6F2D" }
                    : { color: "#000" }
                }
              >
                Career
              </MenuItem>
            </a>
          </Menus>
          {/* <a href="/contact">
            <li
              className="link-name"
              style={
                isHistory === "/contact"
                  ? { color: "#ED6F2D" }
                  : { color: "#000" }
              }
            >
              Contact us
            </li>
          </a> */}
          {/* <a href="/career">
            <li
              className="link-name"
              style={
                isHistory === "/career"
                  ? { color: "#ED6F2D" }
                  : { color: "#000" }
              }
            >
              Career
            </li>
          </a> */}
          <a href="/book-a-demo">
            <li
              className="link-name"
              style={{
                backgroundColor: "#f99a4d",
                color: "#fff",
                padding: "8px 1rem",
                borderRadius: "4px",
              }}
            >
              Book a Demo
            </li>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Header;
