import React from "react";
import { Col, Row } from "react-bootstrap";
import Header from "../Header";
import pic1 from "../../img/e1.png";
import pic2 from "../../img/e2.png";

const Donor = () => {
  return (
    <div className="header-marg padding-side">
      <Header />
      <div className="text-center py-4">
        <li style={{ fontSize: "1.6rem", fontWeight: "800" }}>Donors</li>
        <li className="mt-1">
          9th Block's platform facilitates collaboration among social
          responsibility stakeholders by mapping and aligning donors with
          community needs. Our digital and consultation solutions encourage
          donors to take action leading to more impact.
        </li>
      </div>

      <Row className="mx-0 align-items-center">
        <Col xs={12} lg={5} className="mt-5">
          <div>
            <img
              src={pic1}
              alt=""
              style={{
                borderRadius: "50%",
                boxShadow: "0 0 15px 10px rgba(0,0,0,0.4)",
              }}
            />
          </div>
        </Col>
        <Col xs={12} lg={7} className="mt-5">
          <div>
            <li style={{ fontSize: "1.4rem", fontWeight: "600" }}>Matching</li>
            <li className="mt-2">
              Get matched with impact program implementation partners through
              relevant matching and vetting
            </li>
            <li className="mt-2">
              Our platform matches you with the right community partners that
              align with your goals, while accessing significant tax savings and
              serving the society. 9th Block also helps you extensively reduce
              waste by sharing surplus resources and combating unsustainable
              waste disposal.
            </li>
          </div>
        </Col>
      </Row>

      <Row className="mx-0 align-items-center">
        <Col xs={12} lg={7} className="mt-5">
          <div>
            <li style={{ fontSize: "1.4rem", fontWeight: "600" }}>
              Impact Assessment & Reporting
            </li>
            <li className="mt-2">
              Stay in the know by monitoring real-time program progress and
              deciphering direct impact.
            </li>
            <li className="mt-2">
              Build an effective and customized evaluation framework for your
              social impact program by monitoring real-time conversations and
              analytics, connecting internal and external teams with your
              mission, and understanding their direct impact. Furthermore, avail
              financial incentives when donating to a 501(c)(3) non-profit to
              maximize your ROI further.
            </li>
          </div>
        </Col>
        <Col xs={12} lg={5} className="mt-5">
          <div>
            <img
              src={pic2}
              alt=""
              style={{
                borderRadius: "50%",
                boxShadow: "0 0 15px 10px rgba(0,0,0,0.4)",
              }}
            />
          </div>
        </Col>
      </Row>

      <Row className="mx-0 align-items-center">
        <Col xs={12} lg={5} className="mt-5">
          <div>
            <img
              src={pic1}
              alt=""
              style={{
                borderRadius: "50%",
                boxShadow: "0 0 15px 10px rgba(0,0,0,0.4)",
              }}
            />
          </div>
        </Col>
        <Col xs={12} lg={7} className="mt-5">
          <div>
            <li style={{ fontSize: "1.4rem", fontWeight: "600" }}>
              Employee Engagement
            </li>
            <li className="mt-2">
              Involve your employees in social impact activities and lead with
              meaningful purpose.
            </li>
            <li className="mt-2">
              Now more than ever, employees want to join organizations leading
              with purpose. With our engagement programs, your employees
              contribute to social impact projects in roles of their choice as
              volunteers or through payroll donations. Engaged employees
              advocate for your brand, improving goodwill and attracting new
              talent. 9th Block’s platform and resources will help them champion
              social causes and give back to the community.
            </li>
          </div>
        </Col>
      </Row>

      <Row className="mx-0 align-items-center">
        <Col xs={12} lg={7} className="mt-5">
          <div>
            <li style={{ fontSize: "1.4rem", fontWeight: "600" }}>ESG</li>
            <li className="mt-2">
              Simplify ESG management through efficient processes using our
              digital tools and our consultations
            </li>
            <li className="mt-2">
              ESG management is complex. Our goal – simplify the process, making
              it efficient yet flawless. We provide you with tech and consultant
              support so you can extract relevant error-free data, build
              resilience through risk assessment and mitigation, and acquire
              more ROI through strategic solutions. 9th Block will also furnish
              different compliance reports for varied global frameworks.
            </li>
          </div>
        </Col>
        <Col xs={12} lg={5} className="mt-5">
          <div>
            <img
              src={pic2}
              alt=""
              style={{
                borderRadius: "50%",
                boxShadow: "0 0 15px 10px rgba(0,0,0,0.4)",
              }}
            />
          </div>
        </Col>
      </Row>

      <Row className="mx-0 align-items-center">
        <Col xs={12} lg={5} className="my-4">
          <div>
            <img
              src={pic1}
              alt=""
              style={{
                borderRadius: "50%",
                boxShadow: "0 0 15px 10px rgba(0,0,0,0.4)",
              }}
            />
          </div>
        </Col>
        <Col xs={12} lg={7} className="my-4">
          <div>
            <li style={{ fontSize: "1.4rem", fontWeight: "600" }}>
              Program Investment Management
            </li>
            <li className="mt-2">
              Make your CSR vision a reality with a holistic approach, from
              start to end.
            </li>
            <li className="mt-2">
              Get involved in social projects from initiation to impact,
              bringing your vision to life using our 360-degree approach. Our
              team will help craft your community impact strategy, conduct need
              assessments, profile NGOs, manage proposals, support field
              operations, audit results, and craft exit strategies. You get
              complete visibility on project implementation through 9th Block's
              centralized platform.
            </li>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Donor;
