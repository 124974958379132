import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import mainPic from "../../img/main-pic.png";
import mainEffect from "../../img/main-effect.png";
import mainPicEffect from "../../img/main-pic-effect.png";
import HomeProblem from "../../img/home-problem-solve.png";
import HomeProblemEffect from "../../img/home-problem-effect.png";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";

function HomePage() {
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="">
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ height: "100%" }}
      >
        <Row className="mx-0 d-flex align-items-center main-flex-lg padding-side">
          <Col className="px-0">
            <motion.div
              initial={{ translateX: "-50%" }}
              animate={{ translateX: "0%" }}
              transition={{
                type: "spring",
                stiffness: 200,
                damping: 50,
              }}
              style={{ float: "right" }}
            >
              {/* <li className="home-title">Empowering organizations </li> */}
              <li className="home-title">
                Make Positive Social Impact a Reality
              </li>
              {/* <li className="home-title2 mt-3" style={{ fontWeight: "500" }}>
                to drive their social impact goals leveraging the power of
                technology
              </li> */}
              {/* <li className="home-title2 mt-3" style={{ fontWeight: "500" }}>
                We Enable organizations to achieve their social impact goals
                through technology solutions and expert consultations
              </li> */}
              <li className="home-title2 mt-3" style={{ fontWeight: "500" }}>
                9th Block’s collaborative platform powers purpose and community
                impact through integrated tech and expert consultations
              </li>
              <NavLink to="/contact">
                <button className="btn-start mt-4 py-2 px-5">
                  Book Free Consultation
                </button>
              </NavLink>
            </motion.div>
          </Col>
          <Col className="px-0 text-center">
            <motion.div
              initial={{ translateX: "50%" }}
              animate={{ translateX: "0%" }}
              transition={{
                type: "spring",
                stiffness: 200,
                damping: 50,
              }}
            >
              <img src={mainPicEffect} className="main-pic-effect" alt="" />
              <img
                src={mainPic}
                className="home-main-pic"
                style={{ marginTop: "70px" }}
                alt=""
              />
            </motion.div>
          </Col>
        </Row>
        {/* <div className="text-center"> */}

        {/* <li className="mission-title">
            Make Positive Social Impact a Reality
          </li>
          <li className="mission-title2 mt-3">
            We Enable organizations to achieve their social impact goals through
            technology solutions and expert consultations
          </li>
          <Link to="/book-a-demo">
            <button className="btn-start px-4 mt-4" style={{ width: "250px" }}>
              Book Free Consultation
            </button>
          </Link> */}
        {/* <li className="mission-title2 mt-3">
            Corporates: Employee Engagement │ Volunteering │ CSR Strategy │ Deep
            Impact Analysis │ Reporting
          </li>
          <li className="mission-title2 mt-3">
            NGOs: Fundraising │ Donor Management │ Knowledge Management │
            Marketing & Branding
          </li> */}
        {/* <Row className="mt-2">
            <Col xs={12} lg={6} className="text-right-web">
              <Link to="/book-a-demo">
                <button
                  className="btn-start px-4 mt-4"
                  style={{ width: "250px" }}
                >
                  Book Demo
                </button>
              </Link>
            </Col>
            <Col xs={12} lg={6} className="text-left-web">
              <Link to="/book-a-demo">
                <button
                  className="btn-start px-4 mt-4"
                  style={{ width: "250px" }}
                >
                  Book Free Consultation
                </button>
              </Link>
            </Col>
          </Row> */}
        {/* </div> */}
      </div>
    </div>
  );
}

export default HomePage;

// function Old() {
//   return (
//     <>
//       <Row className="mx-0 d-flex align-items-center main-flex-lg padding-side">
//         <Col className="px-0">
//           <motion.div
//             initial={{ translateX: "-50%" }}
//             animate={{ translateX: "0%" }}
//             transition={{
//               type: "spring",
//               stiffness: 200,
//               damping: 50,
//             }}
//             style={{ float: "right" }}
//           >
//             <li className="home-title">Empowering organizations </li>
//             <li className="home-title2 mt-3" style={{ fontWeight: "500" }}>
//               to drive their social impact goals leveraging the power of
//               technology
//             </li>
//             <NavLink to="/contact">
//               <button className="btn-start mt-4">Get Started</button>
//             </NavLink>
//           </motion.div>
//         </Col>
//         <Col className="px-0 text-center">
//           <motion.div
//             initial={{ translateX: "50%" }}
//             animate={{ translateX: "0%" }}
//             transition={{
//               type: "spring",
//               stiffness: 200,
//               damping: 50,
//             }}
//           >
//             <img src={mainPicEffect} className="main-pic-effect" alt="" />
//             <img
//               src={mainPic}
//               className="home-main-pic"
//               style={{ marginTop: "110px" }}
//               alt=""
//             />
//           </motion.div>
//         </Col>
//       </Row>

//       <div className="mission-effect-bg">
//         <div className="mx-0 mission-flex padding-side">
//           <img src={mainEffect} className="main-effect only-web" alt="" />
//           <div className="text-center">
//             <li className="mission-title font-fam">Mission</li>
//             {/* <li className="mission-title2 ">
//               We are 9thBlock: The building block of social change.
//               Accountability, transparency, responsibility,and inclusion are
//               words that pepper today's deliberations as our society has grown
//               more conscious and is demanding change. More and more individuals
//               and organisations are increasingly becoming committed to the aim
//               of social good. This is where 9thBlock makes a difference
//             </li> */}
//             <li
//               className="mission-title2 mt-3"
//               style={{ fontWeight: "600", fontSize: "1.4rem" }}
//             >
//               We are on a mission to transform the social impact ecosystem by
//               creating transparency, adding efficiency and building engaging
//               partnerships among all stakeholders.With our technology platform
//               the key stakeholders can work collaboratively with more efficiency
//               and transparency. Our team brings passion and decades of
//               experience in technology and social impact space.
//             </li>
//           </div>
//         </div>
//       </div>

//       <Row className="mx-0 padding-side mt-5">
//         <Col>
//           <div>
//             <img src={HomeProblem} className="home-main-pic" alt="" />
//           </div>
//         </Col>
//         <Col>
//           <div className="mt-5">
//             <img
//               src={HomeProblemEffect}
//               className="home-problem-effect"
//               alt=""
//             />
//             <li className="home-problem-title ">
//               <span>What</span>&nbsp;
//               <span className="font-fam" style={{ color: "#ED6F2D" }}>
//                 problem
//               </span>
//             </li>
//             <li
//               className="home-problem-title "
//               style={{ lineHeight: "1.8rem" }}
//             >
//               are we solving?
//             </li>
//             {/* <li className="home-problem-title2 mt-4">
//               Over the years, we have witnessed that technology has become the
//               medium to address age old inequities via new digital solutions. It
//               remains to be a powerful instrument through which we can empower
//               the needy, create an inclusive society, and provide opportunities
//               to the weak. Using this very technology, we will drive up further
//               collaborations between non-profit organizations and corporate
//               institutions, in a more transparent and engaging manner. To wit,
//               by working closely, we intend to provide continuous real time
//               monitoring, extending data that can be used by our partners to
//               establish, adapt, and administer their organisation.
//             </li> */}
//             <li
//               className="home-problem-title2 mt-4"
//               style={{ fontSize: "1.1rem", fontWeight: "550" }}
//             >
//               Over the years, we have witnessed that technology has become the
//               medium to address age old inequities via new digital solutions. It
//               remains to be a powerful instrument through which we can empower
//               the needy, create an inclusive society, and provide opportunities
//               to the weak. Using this very technology, we will drive up further
//               collaborations between non-profit organizations and corporate
//               institutions, in a more transparent and engaging manner.
//             </li>
//           </div>
//         </Col>
//       </Row>
//     </>
//   );
// }
