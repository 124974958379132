import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import effect1 from "../../img/resource-effect.png";
import Blog from "./Blog";
import Partnership from "./Partnership";
import tx from "../../img/res-tx.png";
import { NavLink } from "react-router-dom";
import Header from "../Header";

function Resource() {
  const [isBlog, setBlog] = useState(1);

  const onBlog = (i) => {
    setBlog(i);
  };

  return (
    <>
      <Header />
      <div className="header-marg ">
        <div className="product">
          <div className="hue res-img"></div>
          {/* <img src={productImg} alt="" /> */}
        </div>
        <img
          src={tx}
          className="mb-5 padding-side only-web"
          width="60%"
          alt=""
        />
        <img
          src={tx}
          className="mb-5 mx-4 only-mob text-center"
          width="90%"
          alt=""
        />

        <div className="padding-side mt-4">
          <Row className="mx-0  ">
            <Col xs={12} lg={3} className="only-web px-0">
              <div className="mt-5">
                <li
                  className={`contact-trial mt-3 ${
                    isBlog === 1 ? "colr" : null
                  }`}
                  onClick={() => onBlog(1)}
                >
                  Partnership Program
                </li>
                <hr style={{ width: "140px" }} />
                <li
                  className={`contact-trial mt-3 ${
                    isBlog === 2 ? "colr" : null
                  }`}
                  onClick={() => onBlog(2)}
                >
                  Blog
                </li>
                <img src={effect1} className="edge-effect" alt="" />
              </div>
            </Col>
            <Col xs={12} lg={3} className="only-mob px-0">
              <div className=" d-flex justify-content-around">
                <li
                  className={`contact-trial mt-3 ${
                    isBlog === 1 ? "colr" : null
                  }`}
                  onClick={() => onBlog(1)}
                >
                  Partnership Program
                </li>

                <li
                  className={`contact-trial mt-3 ${
                    isBlog === 2 ? "colr" : null
                  }`}
                  onClick={() => onBlog(2)}
                >
                  Blog
                </li>
              </div>
            </Col>
            <Col xs={12} lg={9} className="px-0">
              <div className={`${isBlog === 1 ? "d-block" : "d-none"}`}>
                <Partnership />
              </div>
              <div className={`${isBlog === 2 ? "d-block" : "d-none"}`}>
                <Blog />
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div className={`sol-more more ${isBlog === 2 ? "d-block" : "d-none"}`}>
        <div className="more-colr">
          <NavLink to="/contact">
            <button className="btn-start" style={{ padding: "15px 50px" }}>
              Get started
            </button>
          </NavLink>
        </div>
      </div>
    </>
  );
}

export default Resource;
