import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const Ngos = () => {
  const [tab, setTab] = useState(1);

  return (
    <>
      <div
        className="my-5 "
        style={{ backgroundColor: "rgba(203, 214, 226, 0.5)" }}
      >
        <div className="py-5  padding-side text-center">
          <li className="" style={{ fontSize: "2.4rem", fontWeight: "800" }}>
            Driving business growth with purpose is a pressing priority
          </li>
          <li className=" mt-2" style={{ fontSize: "1.2rem" }}>
            53% of revenues of the 500 largest US companies and 49% of revenues
            of the 1,200 largest global companies come from business activities
            that support SDGs*
          </li>
        </div>
      </div>

      <div className="padding-side my-5">
        {/* <div className="pt-4 d-flex justify-content-around text-center">
        <li
          onClick={() => setTab(1)}
          style={
            tab === 1
              ? { borderBottom: "1px solid #f99a4d", cursor: "pointer" }
              : { cursor: "pointer" }
          }
        >
          NGOs
        </li>
        <li
          onClick={() => setTab(2)}
          style={
            tab === 2
              ? { borderBottom: "1px solid #f99a4d", cursor: "pointer" }
              : { cursor: "pointer" }
          }
        >
          Corporates
        </li>
        <li
          onClick={() => setTab(3)}
          style={
            tab === 3
              ? { borderBottom: "1px solid #f99a4d", cursor: "pointer" }
              : { cursor: "pointer" }
          }
        >
          PSUs
        </li>
        <li
          onClick={() => setTab(4)}
          style={
            tab === 4
              ? { borderBottom: "1px solid #f99a4d", cursor: "pointer" }
              : { cursor: "pointer" }
          }
        >
          Donors
        </li>
      </div> */}

        {/* {tab === 1 ? (
        <div className=" mt-3 ngo-card">
          <li>Holistic Branding, Fundraising & Grants Management</li>
          <li>
            Improve visibility in the CSR ecosystem through streamlined
            processes and simplified reporting
          </li>
          <li style={{ fontSize: "1.2rem" }}>
            Empowering NGOs with accountability and credibility by matching them
            to the right stakeholders, expanding their funding needs, engaging
            with volunteers, mobilizing resources faster and sharing impact
            stories.
          </li>
          <Link to="/">
            <button className="btn-start px-3 mt-4 py-1">Learn more..</button>
          </Link>
        </div>
      ) : tab === 2 ? (
        <div className="ngo-card mt-3">
          <li style={{ fontSize: "1.2rem" }}>
            Engaged Partnership & Transparency
          </li>
          <li style={{ fontSize: "1.2rem" }}>
            Get involved in CSR projects from initiation to impact, by managing
            grants, reports, employee engagement, and more
          </li>
          <Link to="/">
            <button className="btn-start px-3 mt-4 py-1">Learn more..</button>
          </Link>
        </div>
      ) : tab === 3 ? (
        <div className="ngo-card mt-3">
          <li style={{ fontSize: "1.2rem" }}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab, facere
            aliquid. Voluptates placeat possimus delectus, repudiandae
            molestiae, laudantium natus iusto eligendi accusantium sint tempora,
            distinctio nostrum corrupti hic
          </li>
          <Link to="/">
            <button className="btn-start px-3 mt-4 py-1">Learn more..</button>
          </Link>
        </div>
      ) : tab === 4 ? (
        <div className="ngo-card mt-3">
          <li style={{ fontSize: "1.2rem" }}>
            Fostering collaboration by matching donors and volunteers with
            relevant projects, providing digital and consultation solutions, and
            encouraging action for good causes, benefiting the community
          </li>
          <Link to="/">
            <button className="btn-start px-3 mt-4 py-1">Learn more..</button>
          </Link>
        </div>
      ) : null} */}

        <Row className="mx-0">
          <li
            className="text-center mt-4"
            style={{ fontSize: "2.4rem", fontWeight: "800" }}
          >
            Power social good and inspire community impact
          </li>
          <Col xs={12} lg={6} className="mt-4">
            <div className="card p-5" style={{ cursor: "pointer" }}>
              <li
                style={{
                  fontSize: "1.6rem",
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                NGOs
              </li>
              <li className="mt-1">
                Empowering NGOs with accountability and credibility by matching
                them to the right stakeholders, expanding their funding needs,
                engaging with volunteers, mobilizing resources faster and
                sharing impact stories.
              </li>
            </div>
          </Col>
          <Col xs={12} lg={6} className="mt-4">
            <div className="card p-5" style={{ cursor: "pointer" }}>
              <li
                style={{
                  fontSize: "1.6rem",
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                Donors
              </li>
              {/* <li className="mt-1">
              Fostering collaboration by matching donors and volunteers with
              relevant projects, providing digital and consultation solutions,
              and encouraging action for good causes, benefiting the community
            </li> */}
              <li className="mt-1">
                collaboration by matching donors and volunteers with relevant
                projects, providing visibility on the impact they drive, and
                strategizing their investment on social good. Also drive
                employee engagement for corporates.
              </li>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Ngos;
