import React from "react";
import { Col, Row } from "react-bootstrap";
import eb1s from "../../img/eb1s.png";
import eb1 from "../../img/eb1.png";
import eb2 from "../../img/eb2.png";
import eb3 from "../../img/eb3.png";
import eb4 from "../../img/eb4.png";
import eb5 from "../../img/eb5.png";
import eb6 from "../../img/eb6.png";

const EdgeBenefit = () => {
  return (
    <div className="padding-side mt-5">
      <li className="benefit text-center mb-2">Benefits</li>
      <Row className="mx-0">
        <Col xs={12} lg={6}>
          <div className="mt-5">
            <li style={{ fontWeight: "600", fontSize: "1.4rem" }}>
              <span className="font-fam" style={{ color: "#4EAEBB" }}>
                Secured{" "}
              </span>
              <span>platform</span>
            </li>
            <li className="mt-2" style={{ maxWidth: "400px" }}>
              We provide a single cloud based platform which is completely
              secured and you can manage all the volunteers from a single place
              and improve the collaboration and engagement between them and can
              thoroughly track them while sitting from a single place.
            </li>
          </div>
        </Col>
        <Col xs={12} lg={6}>
          <div style={{ position: "relative" }}>
            <img src={eb1} width="100%" alt="" />
            <img
              src={eb1s}
              style={{ position: "absolute", left: "40%", top: "30%" }}
              alt=""
            />
          </div>
        </Col>

        <Col xs={12} lg={6}>
          <div style={{ position: "relative" }}>
            <img src={eb2} width="80%" alt="" />
            <img
              src={eb3}
              width="80%"
              style={{ position: "absolute", left: "25%", top: "20%" }}
              alt=""
            />
          </div>
        </Col>
        <Col xs={12} lg={6}>
          <div className="mt-5">
            <li style={{ fontWeight: "600", fontSize: "1.4rem" }}>
              <span className="font-fam" style={{ color: "#4EAEBB" }}>
                Task
              </span>
              <span> management</span>
            </li>
            <li className="mt-2" style={{ maxWidth: "400px" }}>
              Platform where we can assign tasks and continuously track. As the
              users they can continuously share their user stories which
              enforces a kind of 360 degree feedback and introduces the concept
              of reflection. The administrators and volunteers have a way to
              continuously provide the feedback and have an engagement and
              experience.
            </li>
          </div>
        </Col>

        <Col xs={12} lg={6}>
          <div className="mt-5">
            <li style={{ fontWeight: "600", fontSize: "1.4rem" }}>
              <span className="font-fam" style={{ color: "#4EAEBB" }}>
                Collaboration
              </span>
              <span> between people</span>
            </li>
            <li className="mt-2" style={{ maxWidth: "400px" }}>
              We provide a single cloud based platform which is completely
              secured and you can manage all the volunteers from a single place
              and improve the collaboration and engagement between them and can
              thoroughly track them while sitting from a single place.
            </li>
          </div>
        </Col>
        <Col xs={12} lg={6}>
          <div className="text-center">
            <img src={eb4} style={{ height: "120px", width: "300px" }} alt="" />
            <img src={eb5} style={{ height: "120px", width: "300px" }} alt="" />
            <img src={eb6} style={{ height: "120px", width: "300px" }} alt="" />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default EdgeBenefit;
