import React from "react";
import Header from "../Header";
import Benefit from "./Benefit";
import HomeCard from "./HomeCard";
import HomeEvent from "./HomeEvent";
import HomePage from "./HomePage";
import Ngos from "./Ngos";
import Products from "./Products";
import WhyUs from "./WhyUs";

function Home() {
  return (
    <div className="header-marg ">
      <Header />
      <HomePage />
      {/* <WhyUs /> */}
      <Benefit />
      <HomeCard />
      <Ngos />
      <Products />
      <HomeEvent />
    </div>
  );
}

export default Home;
