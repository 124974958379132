import React from "react";
import HomeCardNew from "./home/HomeCardNew";
import HomePageNew from "./home/HomePageNew";
import logo from "../img/logo.png";
import { Facebook, Instagram, LinkedIn } from "@material-ui/icons";

const Landingpage = () => {
  return (
    <>
      <div className="header">
        <div className="header-flex">
          <div className="mx-4">
            <a href="/">
              <img src={logo} className="logo" alt="" />
            </a>
          </div>
          <div className="link-a">
            <a href="https://www.facebook.com/9thblock-103229902139875">
              <Facebook />
            </a>
            <a href=" https://www.instagram.com/9thblockinc/">
              <Instagram />
            </a>
            <a href="https://www.linkedin.com/company/9thblock-inc">
              <LinkedIn />
            </a>
          </div>
        </div>
      </div>

      <div className="header-marg">
        <HomeCardNew />
        <HomePageNew />
        {/* <HomePageNew2 /> */}
      </div>
    </>
  );
};

export default Landingpage;
