import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import benefit from "../../img/benefit-effect3.png";
import laptop from "../../img/feature-laptop.png";
import f1 from "../../img/f1.png";
import f2 from "../../img/f2.png";
import f3 from "../../img/f3.png";
import f4 from "../../img/f4.png";
import f5 from "../../img/f5.png";
import f6 from "../../img/f6.png";
import f7 from "../../img/f7.png";
import f8 from "../../img/f8.png";
import f9 from "../../img/f9.png";
import f10 from "../../img/f10.png";
import AOS from "aos";

function Feature() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <div>
      <Row className="mx-0 padding-side mt-4">
        <Col xs={12} lg={6}>
          <li className="benefit text-center only-mob mt-5">Benefits</li>
          <div className="only-web" style={{ marginTop: "120px" }}>
            <img src={f1} alt="" />
            <li
              className="mt-2 home-problem-title2"
              style={{ maxWidth: "450px" }}
            >
              We provide a single cloud based platform which is completely
              secured and you can manage all the volunteers from a single place
              and improve the collaboration and engagement between them and can
              thoroughly track them while sitting from a single place.
            </li>
          </div>
          <div className="only-mob mt-5">
            <img src={f1} alt="" />
            <li
              className="mt-2 home-problem-title2"
              style={{ maxWidth: "450px" }}
            >
              We provide a single cloud based platform which is completely
              secured and you can manage all the volunteers from a single place
              and improve the collaboration and engagement between them and can
              thoroughly track them while sitting from a single place.
            </li>
          </div>

          <div className="mt-5">
            <img src={f2} alt="" />
            <li
              className="mt-2 home-problem-title2"
              style={{ maxWidth: "500px" }}
            >
              Our platform is completely cloud based and highly secured.
            </li>
          </div>
        </Col>
        <Col xs={12} lg={6}>
          <div>
            <img src={benefit} className="card-effect only-web" alt="" />
            <li className="benefit text-center only-web">Benefits</li>
            <div className="mt-5 ">
              <img src={f3} alt="" />
              <li
                className="mt-2 home-problem-title2"
                style={{ maxWidth: "500px" }}
              >
                Task management, where we can assign tasks and continuously
                track. As the users they can continuously share their user
                stories which enforces a kind of 360 degree feedback and
                introduces the concept of reflection. The administrators and
                volunteers have a way to continuously provide the feedback and
                have an engagement and experience.
              </li>
            </div>
            <div className="mt-5">
              <img src={f4} alt="" />
              <li
                className="mt-2 home-problem-title2"
                style={{ maxWidth: "500px" }}
              >
                Zoom , text and whatsapp integration for better engagement with
                volunteers and administration.
              </li>
            </div>
          </div>
        </Col>
      </Row>

      <div className="mt-5 feature">
        <Row className="mx-0 padding-side">
          <Col xs={12} lg={6}>
            <div className="my-4">
              <li className="mission-title">Features</li>
              <img
                src={laptop}
                alt=""
                width="100%"
                data-aos={"zoom-in-right"}
                data-aos-offset={"200"}
              />
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="d-flex mt-3 align-items-center">
              <div className="feature-img-box">
                <img src={f1} alt="" />
              </div>
              <li className="feature-tx">Text notification</li>
            </div>
            <div className="d-flex mt-3 align-items-center">
              <div className="feature-img-box">
                <img src={f2} alt="" />
              </div>
              <li className="feature-tx text-start">
                Out of the box integration with Zoom, Whatsapp
              </li>
            </div>
            <div className="d-flex mt-3 align-items-center">
              <div className="feature-img-box">
                <img src={f3} alt="" />
              </div>
              <li className="feature-tx">Volunteer management</li>
            </div>
            <div className="d-flex mt-3 align-items-center">
              <div className="feature-img-box">
                <img src={f4} alt="" />
              </div>
              <li className="feature-tx">Beneficiary management</li>
            </div>
            <div className="d-flex mt-3 align-items-center">
              <div className="feature-img-box">
                <img src={f5} alt="" />
              </div>
              <li className="feature-tx">Reporting and Dashboard analytics</li>
            </div>
            <div className="d-flex mt-3 align-items-center">
              <div className="feature-img-box">
                <img src={f6} alt="" />
              </div>
              <li className="feature-tx">Collaboration</li>
            </div>
            <div className="d-flex mt-3 align-items-center">
              <div className="feature-img-box">
                <img src={f7} alt="" />
              </div>
              <li className="feature-tx">Task management</li>
            </div>
            <div className="d-flex mt-3 align-items-center">
              <div className="feature-img-box">
                <img src={f8} alt="" />
              </div>
              <li className="feature-tx">User stories</li>
            </div>
            <div className="d-flex mt-3 align-items-center">
              <div className="feature-img-box">
                <img src={f9} alt="" />
              </div>
              <li className="feature-tx">Ownership of Data</li>
            </div>
            <div className="d-flex mt-3 align-items-center">
              <div className="feature-img-box">
                <img src={f10} alt="" />
              </div>
              <li className="feature-tx">Cloud based</li>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Feature;
