import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
// import cardImg1 from "../../img/card-img1.png";
// import cardImg2 from "../../img/card-img2.png";
// import cardImg3 from "../../img/psu.png";
import cardEffect from "../../img/card-effect.png";
import card1 from "../../img/card1.png";
import card2 from "../../img/card2.png";
import card3 from "../../img/card3.png";
import AOS from "aos";

function HomeCard() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <div className="home-card-box p-5 mt-4">
      <img src={cardEffect} className="card-effect only-web" alt="" />
      <Row className="mx-0 padding-side w-100">
        <li className="mission-title text-center ">
          <span>Whom do we </span>
          <span className="font-fam" style={{ color: "#4EAEBB" }}>
            work
          </span>
          &nbsp;
          <span>for?</span>
        </li>

        <Col xs={12} lg={4} className="mt-4">
          <div className="home-card-new mx-auto">
            <img src={card1} width="100%" height="100%" alt="" />
            <li className="home-card-new-2">Corporates</li>
            <li className="mt-2 text-center" style={{ color: "#fff" }}>
              Identify, Strategize and Analyze CSR investment and employee
              engagement through digital volunteering opportunities
            </li>
          </div>
        </Col>
        <Col xs={12} lg={4} className="mt-4">
          <div className="home-card-new mx-auto">
            <img src={card2} width="100%" height="100%" alt="" />
            <li className="home-card-new-2">NGOs</li>
            <li className="mt-2 text-center" style={{ color: "#fff" }}>
              Identify, Strategize and Analyze CSR investment and employee
              engagement through digital volunteering opportunities
            </li>
          </div>
        </Col>
        <Col xs={12} lg={4} className="mt-4">
          <div className="home-card-new mx-auto">
            <img src={card3} width="100%" height="100%" alt="" />
            <li className="home-card-new-2">PSUs</li>
            <li className="mt-2 text-center" style={{ color: "#fff" }}>
              Identify, Strategize and Analyze CSR investment and employee
              engagement through digital volunteering opportunities
            </li>
          </div>
        </Col>
        {/* <Col xs={12} lg={6}>
          <div className="mt-5">
            <li className="mission-title">Who do we </li>
            <li className="mission-title" style={{ lineHeight: "1.8rem" }}>
              <span style={{ color: "#4EAEBB" }}>work</span>&nbsp;
              <span>for?</span>
            </li>
            <li className="mission-title2 my-5 ">
              It takes a village to create a sustainable and scalable social
              impact. We aim to foster partnership among NGOs, Corporates and
              Foundations to build that village for social development goals.
            </li>
          </div>
        </Col>
        <Col xs={12} lg={6}>
          <div className="home-card p-3 mx-auto" data-aos={"zoom-in"}>
            <img src={cardImg1} width="100%" alt="" />
            <li className="corp mt-2">Corporates</li>
            <li className="mt-2">
              Identify, Strategize and Analyze CSR investment and employee
              engagement through digital volunteering opportunities
            </li>
          </div>
        </Col>
        <Col xs={12} lg={6}>
          <img src={cardEffect} className="card-effect only-web" alt="" />
          <div className="home-card home-card2 p-3 mt-5" data-aos={"zoom-in"}>
            <img src={cardImg2} width="100%" alt="" />
            <li className="corp mt-2">NGOs</li>
            <li className="mt-2">
              A centralized digital platform to manage volunteer engagement and
              run programs at scale beyond physical boundaries
            </li>
          </div>
        </Col>
        <Col xs={12} lg={6}>
          <div
            className="home-card home-card2 p-3 mx-auto mt-5"
            data-aos={"zoom-in"}
          >
            <img src={cardImg3} width="100%" alt="" />
            <li className="corp mt-2">PSU</li>
            <li className="mt-2">
              New technologies can improve NGO participation and process
              monitoring efforts, thus increasing government accountability.
            </li>
          </div>
        </Col> */}
      </Row>
    </div>
  );
}

export default HomeCard;
