import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
// import blog from "../../img/blog.png";
// import s1 from "../../img/s1.png";
// import s2 from "../../img/s2.png";
// import s3 from "../../img/s3.png";
// import s4 from "../../img/s4.png";
// import s5 from "../../img/s5.png";
// import s6 from "../../img/s6.png";
// import s7 from "../../img/s7.png";
// import serviceEffect from "../../img/service-effect.png";
import AOS from "aos";
import { NavLink } from "react-router-dom";
import sol4 from "../../img/sol4.png";
import sol5 from "../../img/sol5.png";

function Service() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  return (
    <>
      {/* <div className="service-bg">
        <Row className="mx-0 padding-side">
          <Col xs={12} lg={4}>
            <div className="mt-5">
              <img src={serviceEffect} className="card-effect" alt="" />
              <img src={s1} alt="" />
              <li className="sol-market mt-3 mb-2">Marketing</li>
              <li className="sol-market2">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                Temporibus labore, vel ab quos et magni aliquid sed
                necessitatibus amet quas dignissimos perferendis tenetur tempora
                nesciunt natus illum odit vero sequi.
              </li>
            </div>
          </Col>

          <Col xs={12} lg={4}></Col>
          <Col xs={12} lg={4}></Col>
          <Col xs={12} lg={4}>
            <div className="mt-5">
              <img src={s2} alt="" />
              <li className="sol-market mt-3 mb-2">Website </li>
              <li className="sol-market2">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                Temporibus labore, vel ab quos et magni aliquid sed
                necessitatibus amet quas dignissimos perferendis tenetur tempora
                nesciunt natus illum odit vero sequi.
              </li>
            </div>
          </Col>
          <Col xs={12} lg={4}>
            <div className="mt-5">
              <img src={s3} alt="" />
              <li className="sol-market mt-3 mb-2">CRM</li>
              <li className="sol-market2">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                Temporibus labore, vel ab quos et magni aliquid sed
                necessitatibus amet quas dignissimos perferendis tenetur tempora
                nesciunt natus illum odit vero sequi.
              </li>
            </div>
          </Col>
          <Col xs={12} lg={4}>
            <div className="mt-5">
              <img src={s4} alt="" />
              <li className="sol-market mt-3 mb-2">Project Management</li>
              <li className="sol-market2">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                Temporibus labore, vel ab quos et magni aliquid sed
                necessitatibus amet quas dignissimos perferendis tenetur tempora
                nesciunt natus illum odit vero sequi.
              </li>
            </div>
          </Col>
          <Col xs={12} lg={4}>
            <div className="mt-5">
              <img src={s5} alt="" />
              <li className="sol-market mt-3 mb-2">Donor Management</li>
              <li className="sol-market2">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                Temporibus labore, vel ab quos et magni aliquid sed
                necessitatibus amet quas dignissimos perferendis tenetur tempora
                nesciunt natus illum odit vero sequi.
              </li>
            </div>
          </Col>
          <Col xs={12} lg={4}>
            <div className="mt-5">
              <img src={s6} alt="" />
              <li className="sol-market mt-3 mb-2">Reporting Tools</li>
              <li className="sol-market2">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                Temporibus labore, vel ab quos et magni aliquid sed
                necessitatibus amet quas dignissimos perferendis tenetur tempora
                nesciunt natus illum odit vero sequi.
              </li>
            </div>
          </Col>
          <Col xs={12} lg={4}>
            <div className="mt-5">
              <img src={s7} alt="" />
              <li className="sol-market mt-3 mb-2">Finance</li>
              <li className="sol-market2">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                Temporibus labore, vel ab quos et magni aliquid sed
                necessitatibus amet quas dignissimos perferendis tenetur tempora
                nesciunt natus illum odit vero sequi.
              </li>
            </div>
          </Col>
        </Row>

        <Row className="mx-0 mt-5 padding-side">
          <Col xs={12} lg={6} className="only-web">
            <div className="text-center mt-5">
              <img
                src={blog}
                className="sol-blog"
                data-aos={"zoom-in"}
                alt=""
              />
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="mt-5" data-aos={"fade-left"}>
              <li className="benefit">Non-Profit Fundraising</li>
              <li className="home-problem-title2 mt-2">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum
                molestias sed corrupti, illo aspernatur rerum expedita soluta
                voluptatum cumque ex. Ex earum ipsam at fugit possimus mollitia
                repellat qui nisi!
              </li>
              <li className="sol-read mt-3">Read more...</li>
            </div>
          </Col>
          <Col xs={12} lg={6} className="only-mob">
            <div className="text-center mt-2">
              <img
                src={sol4}
                className="sol-blog"
                data-aos={"zoom-in"}
                alt=""
              />
            </div>
          </Col>
        </Row>
      </div> */}

      <div className="service-bg-temp">
        <Row className="mx-0 mt-4 padding-side">
          <Col xs={12} lg={6} className="only-web">
            <div className="text-center mt-5">
              <img
                src={sol4}
                className="sol-blog"
                data-aos={"zoom-in"}
                alt=""
              />
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="mt-5" data-aos={"fade-left"}>
              <li className="benefit">Non-Profit Fundraising</li>
              <li className="home-problem-title2 mt-2">
                The goal of nonprofit fundraising is to help your organization
                grow while meeting the community's needs through donations and
                voluntary funds. Business and individual donors can both
                participate in your fundraising campaign.
              </li>
              <a href="/non-profit-fundraising">
                <li className="sol-read mt-3">Read more...</li>
              </a>
            </div>
          </Col>
          <Col xs={12} lg={6} className="only-mob">
            <div className="text-center mt-2">
              <img
                src={sol4}
                className="sol-blog"
                data-aos={"zoom-in"}
                alt=""
              />
            </div>
          </Col>
        </Row>
      </div>

      <Row className="mx-0 padding-side mt-5">
        <Col xs={12} lg={6}>
          <div data-aos={"fade-right"} className="">
            <li className="benefit">Searching right non-profit partner</li>
            <li className="sol-domestic mt-2">Domestic & International</li>
            <li className="home-problem-title2 mt-2">
              A strategic alliance can be formed by finding the right nonprofit
              partner for your corporation. A mutually beneficial relationship
              is forged through a complete alignment between both parties.
              Together, you and your nonprofit partner can build awareness and
              reach your goals - all while making a difference for the causes
              that matter most to you.
            </li>
            <a href="/solution/searching-for-right-non-profit">
              <li className="sol-read mt-3">Read more...</li>
            </a>
          </div>
        </Col>
        <Col xs={12} lg={6}>
          <div className="text-center">
            <img src={sol5} data-aos={"zoom-in"} className="sol-blog" alt="" />
          </div>
        </Col>
      </Row>

      <div className="sol-more more">
        <div className="more-colr">
          <NavLink to="/contact">
            <button className="btn-start" style={{ padding: "20px 50px" }}>
              Start trial
            </button>
          </NavLink>
        </div>
      </div>
    </>
  );
}

export default Service;
