import React from "react";
import { Col, Row } from "react-bootstrap";
import sol1 from "../../img/sol1.png";

function DigitalVol() {
  return (
    <div className="mt-5 padding-side">
      <li
        className=" text-center"
        style={{ fontWeight: "800", fontSize: "1.4rem" }}
      >
        The Essence of Digital Volunteering Platforms
      </li>
      <Row className="mt-4">
        <Col xs={12} lg={6}>
          <li className="mt-3">
            In recent years, technology has become a driving force for economic
            and social change. It is not uncommon for non-profit making
            organizations (NGOs) to recruit volunteers without relying heavily
            on ICT (information and digital technology). To attract the most
            well-prepared and motivated volunteers, organizations in this sector
            should incorporate and use digital platforms. The perception of a
            technological platform is to give non-profits and volunteers access
            to information, tools, and opportunities.
          </li>
          <li className="mt-3">
            Internet use has impacted the organization of businesses around the
            world as a boon in the pandemic. Companies, non-profit
            organizations, and institutions have all noticed changes in
            communication strategies with the development of new technologies.
            Users, volunteers, or anyone interested in the organization's aims
            and goals will be provided with messages through new digital
            channels.
          </li>
          <li className="mt-3">
            Changes in an organization, such as a change in communication and
            marketing strategies, require social networks to play an important
            role. User-generated content (UGC) is content that has been posted
            by users on digital platforms. Besides writing comments or reviews,
            users can voice their opinions or criticism of companies or
            organizations.
          </li>
        </Col>
        <Col xs={12} lg={6}>
          <img src={sol1} alt="" />
        </Col>
      </Row>

      <li className="mt-3">
        Using this new, two-way communication method to communicate with users
        and groups on the Internet has enabled non-profit organizations to use
        communication strategies to convey positive messages about their
        proposals and projects and to promote social movements such as the MeToo
        movement on the Internet, World Environment Day, and climate change
        globally.
      </li>
      <li className="mt-3">
        Changes in both the non-profit-making sector and social institutions are
        also contributing to these new communication strategies based on
        interaction with users. Since the non-profit sector is bringing social
        and non-economic goals to bear on its medium and long-term strategies,
        it has turned to new communication strategies with user interactions on
        digital channels. Non-profit-making organizations that use these
        channels may use factors such as their image, reputation, and visual
        identity to be more easily identified and accepted.
      </li>
      <li className="mt-3">
        Digital channels are now being used by non-profit organizations to
        recruit new volunteers for their projects and to assist them in
        achieving their goals. The participation of volunteers in social
        projects in this sector has changed as a result of platforms for
        attracting volunteers that allow them to join one or more organizations
        that promote similar projects. This research focuses mainly on
        nongovernmental organizations, which are nonprofit and independent from
        governments. This is very different from non-profit organizations, which
        are companies to which charitable taxes have been granted.
      </li>
      <li className="mt-3">
        The greatest strength of a diverse volunteer program is that it makes it
        easier for employees or community members to find a volunteer
        opportunity they would enjoy. Would you be interested in incorporating
        virtual activities for your remote employees or supporters?
      </li>
      <li className="mt-3">
        Volunteers most often come to mind when you think of planting a garden,
        beautifying a local park or creating a vibrant mural at a school. At any
        given time, people around the globe engage virtually in volunteering via
        their computers, their smartphones, and the Internet.
      </li>
      <li className="mt-3">
        A digital volunteer can assist an organization or individual on a short-
        or long-term basis, in whole or in part, most of the time from another
        location. Many volunteer opportunities can be found through the Internet
        - anywhere you have fast Internet access, such as your home, the library
        or a coffee shop.
      </li>
      <li className="mt-3" style={{ fontWeight: "800" }}>
        How do E-Volunteers work?
      </li>
      <li className="mt-3">
        It is easy to work as a volunteer online thanks to digital volunteering.
        The internet has become a popular channel for many organizations to do
        business in the digital age. For grocery shopping, we can order online
        delivery instead of going to the store. Meetings are usually video
        conferencing rather than in person.
      </li>
      <li className="mt-3">
        Volunteering is often associated with working directly with people.
        Museum docents, animal shelters, tutors, and workers in soup kitchens
        are all examples of those workers with the term. Volunteers can perform
        many activities for which salaried staff has neither the time nor the
        resources. Without compensation, volunteers perform crucial tasks.
      </li>
      <li className="mt-3">
        There are a variety of ways that you can volunteer, however. "Back
        office" activities include maintaining membership lists and keeping
        track of a club's finances, for example. In addition to maintaining a
        website's homepage, volunteers can translate a newsletter into various
        languages. Digital volunteering can begin by taking these steps.
      </li>
      <li className="mt-3" style={{ fontWeight: "800" }}>
        Volunteering digitally has many advantages
      </li>
      <li className="mt-3">
        Internet and social media are popular with non-profit clubs and aid
        organizations, resulting in volunteer opportunities. Help in these
        positions often does not require extensive experience or skills. An
        orientation will probably be needed before you begin your work, but it
        shouldn't be too lengthy.
      </li>
      <li className="mt-3">
        People with limited time can also volunteer by doing virtual work. Most
        companies allow flexible hours so that you can work at your own pace.
        Those who are introverted or shy may also benefit from digital
        volunteering! While volunteering your time, you can nonetheless make a
        significant impact, even without engaging with others.
      </li>
      <li className="mt-3" style={{ fontWeight: "800" }}>
        "Digital Volunteering": No matter where you are, you can help
      </li>
      <li className="mt-3">
        Digital volunteering is becoming a more popular method for large,
        non-profit organizations. One example is the United Nations (UN), which
        has set up an online platform for digital volunteers. Interested parties
        can access these opportunities online!
      </li>
      <li className="mt-3">
        The assistance provided by volunteers in the translation field is of
        course increasingly important in the age of globalization. Online
        volunteers are used by UN agencies for editing and writing as well.
        Others, however, who do not have a particular affinity for language, can
        find employment in the technology development field. Digital
        volunteering at the UN is so easy - but that's only the beginning!
      </li>
      <li className="mt-3" style={{ fontWeight: "800" }}>
        Volunteering through digital means at home
      </li>
      <li className="mt-3">
        There are likely to be virtual opportunities that you can find at an
        organization in your hometown as well. Before you begin helping a cause
        you are passionate about, you should mention a few concerns before you
        begin. Among these are how much time you will have available to
        volunteer. It is helpful to manage your time well. It is also important
        to understand your responsibilities ahead of time. When everyone knows
        their responsibilities, the whole organization is better prepared. A
        good plan lets you know exactly what you need to do, especially at the
        beginning.
      </li>
      <li className="my-3">
        Technology is rapidly growing and thus, 9th Block aims to be keeping up
        with this fast-paced world by being a part of the digital volunteering
        community. Digital volunteering platforms assist people from different
        communities to come together and work for the betterment of society as
        well as the environment. We all have our roles to play. The catch is to
        figure out what’s yours!
      </li>
    </div>
  );
}

export default DigitalVol;
