import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import p1 from "../../img/p1.png";
import p2 from "../../img/p2.png";
import p3 from "../../img/p3.png";
import p5 from "../../img/p5.png";
import who1 from "../../img/who1.png";
import who2 from "../../img/who2.png";
import who3 from "../../img/who3.png";
import who4 from "../../img/who4.png";
import why2 from "../../img/why2.png";
import why3 from "../../img/why3.png";
import why4 from "../../img/why4.png";
import effect2 from "../../img/res-effect2.png";
import effect4 from "../../img/home-problem-effect.png";
import { NavLink } from "react-router-dom";
import AOS from "aos";

function PartnershipMob() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <>
      <div className="mt-3">
        <Col xs={12} lg={5} className="only-mob mb-3">
          <img src={effect2} className="card-effect" alt="" />
          <div className="text-center">
            <img src={p1} data-aos={"zoom-in"} className="part-img " alt="" />
          </div>
        </Col>
        <li className="part-title text-center" style={{ fontSize: "2rem" }}>
          Join 9thBlock
        </li>
        <li className="part-title2 text-center">Partner’s Program</li>

        <Row>
          <Col xs={12} lg={7}>
            <div>
              <li className="mt-3 mx-4">
                We are working with training centers and site solution
                integrators. Also working with other complementary partners and
                we are looking for a team who has similar goals which is to
                drive social change.
              </li>
              <NavLink to="/contact">
                <div className="mt-4 text-center">
                  <button className="btn-start">Join now</button>
                </div>
              </NavLink>
            </div>
          </Col>
        </Row>

        <div className=" only-mob">
          <Row className="mx-0 mt-4">
            <Col xs={12} lg={4}>
              <div className="text-center">
                <img
                  src={p2}
                  data-aos={"zoom-in"}
                  className="part-img"
                  alt=""
                />
              </div>
            </Col>
            <Col xs={12} lg={8}>
              <div className="part-marg-top-sm">
                <li className="part-title text-center">What is a </li>
                <li className="part-title3 text-center">Partner’s Program</li>

                <li className="mt-4 sol-blog mx-4">
                  Become our champion and play a crucial role in introducing new
                  corporations to 9thBlock Edge and Hub. Provide solutions that
                  will help them to start, adapt & manage their businesses.
                </li>
              </div>
            </Col>
          </Row>
        </div>

        <Row className="mx-0 part-marg-top">
          <Col xs={12} lg={8}>
            <div style={{ maxWidth: "400px" }}>
              <li
                className="home-event-cont2 mx-4"
                style={{
                  fontSize: "3rem",
                  fontWeight: "800",
                  lineHeight: "4rem",
                }}
              >
                Who can be our Partner?{" "}
              </li>
            </div>
          </Col>
          <Col xs={12} lg={4}>
            <div className="text-center">
              <img src={p3} className="part-img" data-aos={"zoom-in"} alt="" />
            </div>
          </Col>

          <Col xs={12} lg={4}></Col>
          <Col xs={12} lg={8}>
            <div className="only-mob">
              <div className="d-flex mt-5">
                <div className="mx-3">
                  <div className="text-center">
                    <img src={who1} className="who mt-3" alt="" />
                  </div>

                  <li className="home-event-cont2 mt-3">
                    CSR Consultants & Companies
                  </li>
                  <li className="sol-market2">
                    Individual consultant and companies who wants to contribute
                    to economic development and helps to improve the quality of
                    life of local community and society at large.
                  </li>
                </div>
              </div>
            </div>
            <div className="only-mob">
              <div className="d-flex mt-5">
                <div className="mx-3">
                  <div className="text-center">
                    <img
                      src={who2}
                      className="who mt-3"
                      style={{ width: "60px", height: "40px" }}
                      alt=""
                    />
                  </div>

                  <li className="home-event-cont2 mt-3">Solution Providers</li>
                  <li className="sol-market2">
                    People who want to provide solutions and help organisations
                    to find the right partner and bring out the social change in
                    our society.
                  </li>
                </div>
              </div>
            </div>
          </Col>

          <Col xs={12} lg={8}>
            <div className="only-mob">
              <div className="d-flex mt-5">
                <img src={effect4} className="home-problem-effect" alt="" />
                <div className="mx-3">
                  <div className="text-center">
                    <img src={who3} className="who mt-3" alt="" />
                  </div>
                  <li className="home-event-cont2 mt-3">Training Partners</li>
                  <li className="sol-market2">
                    Trainers who has strength to manage CSR initiatives and
                    wants to train volunteers to provide more value to society
                    and the business.
                  </li>
                </div>
              </div>
              <div className="d-flex mt-5">
                <div className="mx-3">
                  <div className="text-center">
                    <img
                      src={who4}
                      className="who mt-3"
                      style={{ width: "34px" }}
                      alt=""
                    />
                  </div>
                  <li className="home-event-cont2 mt-3">Corporates</li>
                  <li className="sol-market2">
                    Corporates who want to analyze their current CSR and
                    sustainability strengths and plan an action plan for
                    accomplishing bigger motivation and impact.
                  </li>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12} lg={4}></Col>
        </Row>

        <div className="why-bg"></div>
        <Row className="mx-0 my-5 why-bg-pd">
          <Col xs={12} lg={8}>
            <div
              style={{ maxWidth: "400px", zIndex: "1", position: "relative" }}
            >
              <li
                className="mx-4"
                style={{
                  fontSize: "3rem",
                  fontWeight: "800",
                  lineHeight: "4rem",
                  color: "#fff",
                }}
              >
                Why partner with us ?
              </li>
            </div>
          </Col>
          <Col xs={12} lg={4}>
            <div className="text-center">
              <img src={p5} data-aos={"zoom-in"} className="part-img" alt="" />
            </div>
          </Col>

          <Col xs={12} lg={4}></Col>
          <Col xs={12} lg={8}>
            <div className="only-mob">
              <div className="d-flex mt-5">
                <div className="mx-3">
                  <div className="text-center">
                    <img
                      src={who4}
                      className="who mt-3"
                      style={{ width: "34px" }}
                      alt=""
                    />
                  </div>

                  <li className="home-event-cont2 mt-3">Great Incentive</li>
                  <li className="sol-market2">
                    The partner will get motivated by providing help to
                    organisations to make a social impact but will also be
                    rewarded for their actions.
                  </li>
                </div>
              </div>
            </div>

            <div className="only-mob">
              <div className="d-flex mt-5">
                <div className="mx-3">
                  <div className="text-center">
                    <img src={why2} className="who mt-3" alt="" />
                  </div>
                  <li className="home-event-cont2 mt-3">Flexibility</li>
                  <li className="sol-market2">
                    Tailored - made programs for different types of partners.
                  </li>
                </div>
              </div>
            </div>
          </Col>

          <Col xs={12} lg={8}>
            <div className="only-mob">
              <div className="d-flex mt-5">
                <div className="mx-3">
                  <div className="text-center">
                    <img
                      src={why3}
                      className="who mt-3"
                      style={{ height: "40px" }}
                      alt=""
                    />
                  </div>
                  <li className="home-event-cont2 mt-3">
                    Partner Training & Engagement
                  </li>
                  <li className="sol-market2">
                    The partners will be trained so that they can actively
                    participate in engaging with different organisations.
                  </li>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12} lg={4}></Col>

          <Col xs={12} lg={4}></Col>
          <Col xs={12} lg={8}>
            <div className="only-mob">
              <div className="d-flex mt-5">
                <div className="mx-3">
                  <div className="text-center">
                    <img
                      src={why4}
                      className="who mt-3"
                      style={{ width: "40px" }}
                      alt=""
                    />
                  </div>

                  <li className="home-event-cont2 mt-3">
                    Be A Part Of Social Change
                  </li>
                  <li className="sol-market2">
                    We provide opportunity to people and help them to determine
                    their own and their society’s needs so that they could work
                    for better future.
                  </li>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default PartnershipMob;
