import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import blog from "../../img/event-blog.png";
import r1 from "../../img/r1.png";
import r2 from "../../img/r2.png";

function BlogPost() {
  return (
    <div className="header-marg ">
      <div className="blog-bg padding-side pt-5">
        <li className="link-name">Education</li>
        <li style={{ fontSize: "1.8rem", fontWeight: "700" }}>Topic name</li>
        <img
          src={blog}
          className="blog-post-ht"
          style={{ width: "100%", marginTop: "1rem" }}
          alt=""
        />
      </div>

      <Row className="mx-0 padding-side blog-post-top">
        <Col xs={12} lg={3} className="only-web">
          <div>
            <li className="posted">Posted In</li>
            <li>Together</li>
            <li className="posted mt-3">Published</li>
            <li className="mb-3">July 22, 2021</li>
          </div>
        </Col>
        <Col xs={12} lg={3} className="only-mob my-3">
          <div className="d-flex justify-content-between">
            <li className="posted">Posted In</li>
            <li className="posted ">Published</li>
          </div>
          <div className="d-flex justify-content-between">
            <li>Together</li>
            <li className="">July 22, 2021</li>
          </div>
        </Col>

        <Col xs={12} lg={9}>
          <div>
            <li className="post-cont">
              The mentorship program will help us further our mission to
              redefine business to consumer communication and commerce.
            </li>
            <li className="mt-4">
              Disney is one of the most iconic names of all time. And today,
              we’re honored to share that Attentive has been selected as one of
              eight companies participating in the 2021 Disney Accelerator, a
              program designed to accelerate the growth of some of the most
              innovative brands from around the world. The 3 month-long
              mentorship program will give Attentive unique access to The Walt
              Disney Company’s leadership team, as well as the company’s
              umbrella of storied brands.
            </li>
            <li className="mt-4">
              We’re incredibly excited about what we’ll learn by participating
              in this program. And we’re looking forward to how this work can
              further benefit our customers’ SMS programs. Already, more than
              3,500 e-commerce and retail brands work with Attentive, powering
              personalized text messaging programs that engage over 200M
              consumers.{" "}
            </li>
            <li className="mt-4">
              We’ve had a front row seat to the e-commerce boom, helping our
              customers grow their subscribers and their revenue exponentially.
              As more and more companies make investments in SMS as both a
              communication and commerce channel, we look forward to continuing
              to innovate so that you can take full advantage of the power of
              this owned channel.
            </li>
            <li className="mt-4">
              In addition to being selected for the Disney Accelerator program,
              Attentive also recently added new investors including SoftBank
              Vision Fund 2, GIC, TCV, and funds and accounts managed by
              BlackRock Private Equity Partners (“BlackRock”).
            </li>
          </div>
          <hr style={{ marginTop: "5rem" }} />

          <div className="res-card my-5">
            <Row className="mx-0">
              <Col xs={12} lg={4} className="px-0">
                <img src={r2} height="100%" className="blog-img-top" alt="" />
              </Col>
              <Col xs={12} lg={8} className="px-0">
                <div className="p-3">
                  <li className="text-end">5 min. read</li>
                  <li className="benefit-title text-start">People</li>
                  <li className="benefit-title mt-1">Topic name</li>
                  <li className="mt-2">
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    Totam nam, sit quod unde expedita, maxime quis sapiente et
                    beatae impedit, libero dolor fugit iste consequatur neque
                    porro accusamus harum vero.
                  </li>
                  <Link to="/resource/blog">
                    <li className="sol-read mt-3">Read Now..</li>
                  </Link>
                </div>
              </Col>
            </Row>
          </div>

          <div className="res-card my-5">
            <Row className="mx-0">
              <Col xs={12} lg={4} className="px-0">
                <img src={r1} height="100%" className="blog-img-top" alt="" />
              </Col>
              <Col xs={12} lg={8} className="px-0">
                <div className="p-3">
                  <li className="text-end">5 min. read</li>
                  <li className="benefit-title text-start">People</li>
                  <li className="benefit-title mt-1">Topic name</li>
                  <li className="mt-2">
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    Totam nam, sit quod unde expedita, maxime quis sapiente et
                    beatae impedit, libero dolor fugit iste consequatur neque
                    porro accusamus harum vero.
                  </li>
                  <Link to="/resource/blog">
                    <li className="sol-read mt-3">Read Now..</li>
                  </Link>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default BlogPost;
