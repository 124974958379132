import React from "react";
import { Link } from "react-router-dom";

const EdgeFeature = () => {
  return (
    <div className="padding-side mt-5 home-event-bg edge-feature-bg">
      <Link to="/contact">
        <button className="btn-start mt-5">Request for demo</button>
      </Link>
    </div>
  );
};

export default EdgeFeature;
