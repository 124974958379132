import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import mobile from "../../img/mobile.png";
import edgeEffect from "../../img/benefit-effect.png";
import AOS from "aos";
import { NavLink } from "react-router-dom";
import benefit from "../../img/benefit-effect3.png";
import laptop2 from "../../img/feature-laptop.png";
import f1 from "../../img/f1.png";
import f2 from "../../img/f2.png";
import f3 from "../../img/f3.png";
import f4 from "../../img/f4.png";
import Header from "../Header";

function Hub() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <>
      <Header />
      <Row>
        <Col xs={12} lg={6} className="only-mob mt-5">
          <div style={{ marginLeft: "1rem" }}>
            <li className="block">9thBlock</li>
            <li className="edge">Hub</li>
            <li className="home-problem-title2 mt-4">
              It's a new age platform which is defining fundraising in a
              different and much more collaborative way where we allow people to
              create their own networks with donors and beneficiaries and help a
              much deeper collaboration to drive funding. The idea is not to
              increase, just fundraising but to do it in a much more effective
              way.
            </li>
            <img src={edgeEffect} className="benefit-effect only-web" alt="" />
            {/* <img
              src={laptop}
              data-aos={"fade-left"}
              data-aos-offset={"200"}
              className="laptop mt-5 hub-laptop only-web"
              width="100%"
              alt=""
            /> */}
          </div>
        </Col>
      </Row>
      <div className="padding-side " style={{ marginTop: "100px" }}>
        {/* <img
          src={laptop}
          data-aos={"fade-left"}
          data-aos-offset={"200"}
          className="laptop hub-laptop only-mob"
          width="100%"
          alt=""
          style={{ marginBottom: "2rem" }}
        /> */}
        <Row className="mx-0 ">
          <Col xs={12} lg={6}>
            <div className="text-center mobile-grd2 only-web">
              <img
                src={mobile}
                alt=""
                data-aos={"fade-right"}
                data-aos-offset={"200"}
                style={{ maxWidth: "400px" }}
              />
            </div>
            <div className="text-center mobile-grd2 only-mob">
              <img
                src={mobile}
                alt=""
                data-aos={"fade-right"}
                data-aos-offset={"200"}
                style={{ maxWidth: "400px" }}
                className="mob-transform"
              />
            </div>
          </Col>
          <Col xs={12} lg={6} className="only-web">
            <div style={{ marginLeft: "1rem" }}>
              <li className="block">9thBlock</li>
              <li className="edge">Hub</li>
              <li className="home-problem-title2 mt-4">
                It's a new age platform which is defining fundraising in a
                different and much more collaborative way where we allow people
                to create their own networks with donors and beneficiaries and
                help a much deeper collaboration to drive funding. The idea is
                not to increase, just fundraising but to do it in a much more
                effective way.
              </li>
              <img
                src={edgeEffect}
                className="benefit-effect only-web"
                alt=""
              />
              {/* <img
                src={laptop}
                data-aos={"fade-left"}
                data-aos-offset={"200"}
                className="laptop mt-5 hub-laptop only-web"
                width="100%"
                alt=""
              /> */}
            </div>
          </Col>
        </Row>
      </div>

      <Row className="mx-0 padding-side mt-4">
        <Col xs={12} lg={6}>
          <li className="benefit text-center only-mob mt-5">Benefits</li>
          <div className="only-web" style={{ marginTop: "120px" }}>
            <img src={f1} alt="" />
            <li
              className="mt-2 home-problem-title2"
              style={{ maxWidth: "500px" }}
            >
              Greater collaboration between donors and nonprofits.
            </li>
          </div>
          <div className="only-mob mt-5">
            <img src={f1} alt="" />
            <li
              className="mt-2 home-problem-title2"
              style={{ maxWidth: "500px" }}
            >
              Greater collaboration between donors and nonprofits.
            </li>
          </div>

          <div className="mt-5">
            <img src={f2} alt="" />
            <li
              className="mt-2 home-problem-title2"
              style={{ maxWidth: "500px" }}
            >
              Allows organisations to get more funding in a very collaborative
              way.
            </li>
          </div>
        </Col>
        <Col xs={12} lg={6}>
          <div>
            <img src={benefit} className="card-effect only-web" alt="" />
            <li className="benefit text-center only-web">Benefits</li>
            <div className=" mt-5">
              <img src={f3} alt="" />
              <li
                className="mt-2 home-problem-title2"
                style={{ maxWidth: "500px" }}
              >
                Offers a variety of volunteer opportunities for employees or
                community members.
              </li>
            </div>
            <div className="mt-5 ">
              <img src={f4} alt="" />
              <li
                className="mt-2 home-problem-title2"
                style={{ maxWidth: "500px" }}
              >
                Give employees a voice, increase participation, and improve
                workplace culture with employee engagement.
              </li>
            </div>
          </div>
        </Col>
      </Row>

      <div className="mt-5 feature feature2">
        <Row className="mx-0 padding-side">
          <Col xs={12} lg={6}>
            <div className="my-4">
              <li className="mission-title">Features</li>
              <img
                src={laptop2}
                alt=""
                width="100%"
                data-aos={"zoom-in-right"}
                data-aos-offset={"200"}
              />
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="d-flex mt-3 align-items-center">
              <div className="feature-img-box">
                <img src={f1} alt="" />
              </div>
              <li className="feature-tx">Collaboration</li>
            </div>
            <div className="d-flex mt-3 align-items-center">
              <div className="feature-img-box">
                <img src={f2} alt="" />
              </div>
              <li className="feature-tx">Fundraising</li>
            </div>
            <div className="d-flex mt-3 align-items-center">
              <div className="feature-img-box">
                <img src={f3} alt="" />
              </div>
              <li className="feature-tx">Volunteer search</li>
            </div>
            <div className="d-flex mt-3 align-items-center">
              <div className="feature-img-box">
                <img src={f4} alt="" />
              </div>
              <li className="feature-tx"> Employee engagement</li>
            </div>
          </Col>
        </Row>
      </div>

      <div className="prod-more more">
        <div className="more-colr">
          <NavLink to="/contact">
            <button className="btn-start" style={{ padding: "20px 50px" }}>
              Know more about the product
            </button>
          </NavLink>
        </div>
      </div>
    </>
  );
}

export default Hub;
