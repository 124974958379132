import { PlayArrow } from "@material-ui/icons";
import React from "react";
import { Col, Row } from "react-bootstrap";
import effect2 from "../../img/contact-effect.png";
import e1 from "../../img/e1.png";
import e2 from "../../img/e2.png";

function Webinar() {
  return (
    <>
      {/* <div className="only-web">
        <div className="d-flex justify-content-between mt-5">
          <li className="res-cat">Education</li>
          <li className="res-cat">Women Empowernment</li>
          <li className="res-cat">Health</li>
          <li style={{ fontWeight: "650" }}>Reset Filter</li>
        </div>
      </div> */}

      {/* <div className="only-mob  mt-5">
        <select name="" id="" className="w-100 py-2 ">
          <option value="">Education</option>
          <option value="">Women Empowernment</option>
          <option value="">Health</option>
        </select>
      </div> */}

      <img src={effect2} className="card-effect only-web" alt="" />
      <div className="res-card blog-mob-top">
        <Row className="mx-0">
          <Col xs={12} lg={4} className="px-0">
            <div className="play-btn">
              <PlayArrow style={{ fontSize: "80px", color: "#fff" }} />
            </div>
            <img
              src={e1}
              width="100%"
              height="100%"
              className="blog-img-top"
              alt=""
            />
          </Col>
          <Col xs={12} lg={8} className="px-0">
            <div className="p-3">
              <div className="d-flex justify-content-between">
                <li className="benefit-title text-start">People</li>
                <li className="">4:28</li>
              </div>

              <li className="benefit-title mt-1">Topic name</li>
              <li className="mt-2">
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Totam
                nam, sit quod unde expedita, maxime quis sapiente et beatae
                impedit, libero dolor fugit iste consequatur neque porro
                accusamus harum vero.
              </li>

              <li className="sol-read mt-3">Watch Now..</li>
            </div>
          </Col>
        </Row>
      </div>

      <div className="res-card my-5">
        <Row className="mx-0">
          <Col xs={12} lg={4} className="px-0">
            <div className="play-btn">
              <PlayArrow style={{ fontSize: "80px", color: "#fff" }} />
            </div>
            <img
              src={e2}
              width="100%"
              height="100%"
              className="blog-img-top"
              alt=""
            />
          </Col>
          <Col xs={12} lg={8} className="px-0">
            <div className="p-3">
              <div className="d-flex justify-content-between">
                <li className="benefit-title text-start">People</li>
                <li className="">4:28</li>
              </div>
              <li className="benefit-title mt-1">Topic name</li>
              <li className="mt-2">
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Totam
                nam, sit quod unde expedita, maxime quis sapiente et beatae
                impedit, libero dolor fugit iste consequatur neque porro
                accusamus harum vero.
              </li>

              <li className="sol-read mt-3">Watch Now..</li>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Webinar;
