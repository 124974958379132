import React from "react";
import { Col, Row } from "react-bootstrap";
import Header from "../Header";
import pic from "../../img/r3.png";
import Avatar from "@material-ui/core/Avatar";
import pic1 from "../../img/s4.png";

const About = () => {
  return (
    <div className="header-marg">
      <Header />
      <div className="home-bg">
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: "100%" }}
        >
          <div className="text-center">
            <li className="mission-title">ABOUT US</li>
            <li className="mission-title2 mt-3">
              9th Block, founded in 2020, is a for-profit and sustainable
              company on a mission to transform the social impact ecosystem. We
              bring together stakeholders through our digital and consultation
              solutions, creating a level-playing field for all organizations –
              big and small. We aim to enhance collaboration and drive positive
              change in communities using transparency, accountability, and
              resource management.
            </li>
          </div>
        </div>
      </div>

      <Row className="mx-0 padding-side py-4 my-5 align-items-center">
        <Col xs={12} lg={8}>
          <div>
            <li style={{ fontSize: "1.4rem", fontWeight: "700" }}>Our Story</li>
            <li className="mt-2">
              Our co-founders volunteered with several non-profits before they
              noticed problems without existing solutions.
            </li>
            <li className="mt-2">
              A plethora of tasks involved human effort, making them
              particularly challenging and time-consuming. Was there a way to
              simplify these and save time, so NGOs and donors could concentrate
              on their core promise – changing lives?
            </li>
            <li className="mt-2">
              Coming from technology backgrounds, the keen minds of our
              co-founders understood the missing link. Fueled by a passion for
              empowering the social development sector, they set out on a
              journey of change, leveraging technology and helping organizations
              transform communities.
            </li>
            <li className="mt-2">
              A few others joined them along the way, sharing their purpose to
              build a platform for positive impact.
            </li>
            <li>And thus, 9th Block was born.</li>
          </div>
        </Col>
        <Col xs={12} lg={4}>
          <div>
            <img
              src={pic}
              alt=""
              style={{ width: "100%", height: "100%", borderRadius: "10px" }}
            />
          </div>
        </Col>
      </Row>

      <div className="padding-side my-5">
        <li
          style={{
            fontSize: "1.4rem",
            fontWeight: "700",
            textAlign: "center",
          }}
        >
          Team
        </li>
        <li className="mt-2 text-center">
          Our team is passionate about social impact, with decades of experience
          building successful products, social responsibility project
          consultations, fundraising, compliance reporting, and deep impact
          analysis.
        </li>
        <Row className="mx-0 mt-3">
          <Col xs={12} lg={3} className="mt-3">
            <div className="ngo-card text-center">
              <Avatar
                alt=""
                src=""
                style={{ margin: "auto", width: "80px", height: "80px" }}
              />
              <li className="mt-2">Shekhar </li>
              <li>Co-founder and CTO</li>
              {/* <li>
                Shekhar leads 9thBlock Inc as Co-founder and Chief Technology
                Officer. He comes with over 20 years of experience in technology
                and is passionate about using it to empower the social
                development sector. He's also a serial entrepreneur, having
                built start-ups solving real-world problems.
              </li> */}
            </div>
          </Col>
          <Col xs={12} lg={3} className="mt-3">
            <div className="ngo-card text-center">
              <Avatar
                alt=""
                src=""
                style={{ margin: "auto", width: "80px", height: "80px" }}
              />
              <li className="mt-2">Nishtha </li>
              <li>Co-founder</li>
              {/* <li>
                Nishtha leads 9thBlock as the Co-founder, bringing in more than
                20 years of experience in technology and social impact sectors.
                She's passionate about leveraging digital tools and her
                expertise to continually solve social impact challenges.
              </li> */}
            </div>
          </Col>
          <Col xs={12} lg={3} className="mt-3">
            <div className="ngo-card text-center">
              <Avatar
                alt=""
                src=""
                style={{ margin: "auto", width: "80px", height: "80px" }}
              />
              <li className="mt-2">lorem</li>
              <li>lorem</li>
            </div>
          </Col>
          <Col xs={12} lg={3} className="mt-3">
            <div className="ngo-card text-center">
              <Avatar
                alt=""
                src=""
                style={{ margin: "auto", width: "80px", height: "80px" }}
              />
              <li className="mt-2">lorem</li>
              <li>lorem</li>
            </div>
          </Col>
        </Row>
      </div>

      <div
        className="padding-side mt-4 py-4"
        style={{ backgroundColor: "#053f5a", color: "#fff" }}
      >
        <li
          style={{
            fontSize: "1.4rem",
            fontWeight: "600",
            textAlign: "center",
          }}
        >
          Values that shape us
        </li>
        <Row className="mx-0">
          <Col xs={12} lg={4} className="mt-4">
            <div className="text-center">
              <img src={pic1} alt="" />
              <li
                className="mt-2"
                style={{ fontWeight: "600", fontSize: "1.08rem" }}
              >
                Trust
              </li>
              <li style={{ fontSize: "0.9rem" }}>
                9th Block’s offerings facilitate faith, an innate value upon
                which responsible relationships are built, between multiple
                stakeholders, creating social capital for project success.{" "}
              </li>
            </div>
          </Col>
          <Col xs={12} lg={4} className="mt-4">
            <div className="text-center">
              <img src={pic1} alt="" />
              <li
                className="mt-2"
                style={{ fontWeight: "600", fontSize: "1.08rem" }}
              >
                Transparency
              </li>
              <li style={{ fontSize: "0.9rem" }}>
                We ensure that we meet our cumulative end goal of uplifting the
                community by enabling transparent communication between key
                participants, from initiation to impact.
              </li>
            </div>
          </Col>
          <Col xs={12} lg={4} className="mt-4">
            <div className="text-center">
              <img src={pic1} alt="" />
              <li
                className="mt-2"
                style={{ fontWeight: "600", fontSize: "1.08rem" }}
              >
                Collaboration
              </li>
              <li style={{ fontSize: "0.9rem" }}>
                Work alongside social goodwill stakeholders, inspiring purpose
                and enriching lives. 9th Block believes in all-around
                collaboration for crafting the utmost community transformation.
              </li>
            </div>
          </Col>
          <Col lg={2}></Col>
          <Col xs={12} lg={4} className="mt-4">
            <div className="text-center">
              <img src={pic1} alt="" />
              <li
                className="mt-2"
                style={{ fontWeight: "600", fontSize: "1.08rem" }}
              >
                Community Impact
              </li>
              <li style={{ fontSize: "0.9rem" }}>
                By the community, for the community. Our platform fosters
                awareness, advocacy, and action by enterprises, employees,
                nonprofits, and donors for maximum impact.
              </li>
            </div>
          </Col>
          <Col xs={12} lg={4} className="mt-4">
            <div className="text-center">
              <img src={pic1} alt="" />
              <li
                className="mt-2"
                style={{ fontWeight: "600", fontSize: "1.08rem" }}
              >
                Innovation
              </li>
              <li style={{ fontSize: "0.9rem" }}>
                9th Block powers purpose through technology and bespoke
                consultations, transforming processes and giving CSR
                stakeholders the tools to create positive change.
              </li>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default About;
