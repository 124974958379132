import React from "react";
import { Col, Row } from "react-bootstrap";
import sol4 from "../../img/sol4.png";

function NonProfitFundraising() {
  return (
    <div className="mt-5 padding-side">
      <li
        className=" text-center"
        style={{ fontWeight: "800", fontSize: "1.2rem" }}
      >
        Raising Reliable Funds for Non-Profits
      </li>
      <Row className="mt-4">
        <Col xs={12} lg={6}>
          <li className="mt-3" style={{ fontWeight: "800" }}>
            What is non-profit funding?
          </li>
          <li className="mt-3">
            The goal of nonprofit fundraising is to help your organization grow
            while meeting the community's needs through donations and voluntary
            funds. Business and individual donors can both participate in your
            fundraising campaign.
          </li>
          <li className="mt-3" style={{ fontWeight: "800" }}>
            What are the complications that occur while raising funds?
          </li>
          <li className="mt-3">
            Funding is a constant subject of conversation among nonprofit
            leaders: How much do we need? What are our options? Where does the
            money come from? This type of question becomes more common and
            pressing during difficult economic times. It is not easy to find the
            answers. It's because nonprofit leaders are far more adept at
            developing programs than they are at finding them, and most donors
            don't know the full extent of their donations' impact.
          </li>
          <li className="mt-3">
            The fuzziness of the financial system has consequences. The money
            does not flow to the areas where it will do the most good when
            nonprofits and funding sources are mismatched. Consequently,
            promising programs are frequently slashed, curtailed or never
            launched. The more money tight gets, the more likely it is that
            there will be a chaotic fundraising scramble.
          </li>
        </Col>
        <Col xs={12} lg={6}>
          <img src={sol4} alt="" />
        </Col>
      </Row>
      <li className="mt-3">
        An organization's long-term funding strategy is rarely discussed in an
        equally clear and concise manner in the nonprofit sector. In other
        words, there are no clear definitions of the different types of funding
        available to nonprofits. This results in a lack of understanding and
        clear thinking rather than a lack of words.
      </li>
      <li className="mt-3">
        There are various types of non-profit organizations, such as
        philanthropic, religious, educational, artistic, or scientific. A
        nonprofit organization does not make profits - any money received goes
        to its cause or to maintain it. Churches and universities may qualify as
        nonprofit organizations.
      </li>
      <li className="mt-3">
        Nonprofit organizations depend on donations for their survival. You can
        fund the work of a nonprofit organization, cover overhead costs, develop
        educational programs, and make improvements that will benefit future
        clients.
      </li>
      <li className="mt-3">
        The fundraising component of your business model is essential no matter
        how big or small your nonprofit is.
      </li>{" "}
      <li className="mt-3" style={{ fontWeight: "800" }}>
        Let's explore what nonprofit fundraising is –
      </li>
      <li className="mt-3">
        These organizations usually generate most of their revenue through
        fundraising. Because of this, nonprofits and charitable organizations
        utilize so many resources.
      </li>
      <li className="mt-3">
        State law heavily restricts nonprofit fundraising. It ensures that
        donors are giving their money to the right organizations and that
        organizations are treating donors fairly.
      </li>
      <li className="mt-3">
        The state government of whichever state the organization operates in
        must be contacted before it can solicit donations. It is also necessary
        to register an organization if it plans to fundraise in another state.
        Furthermore, fundraisers or grants writers must register with the state
        if they are hired by an organization.
      </li>
      <li className="mt-3">
        Commercial co-ventures, which are also known as cause-related marketing,
        are regulated by law. Nonprofit organizations that work with businesses
        to receive a percentage of their sales are known as commercial
        co-ventures. Furthermore, it occurs when two nonprofit organizations
        agree to sell something together and benefit jointly from the sale.
      </li>
      <li className="mt-3">
        The best fundraising practices should be followed by all nonprofit
        organizations. If you understand the legal nuances of nonprofit
        fundraising, you'll be able to identify which fundraising approaches are
        best for your nonprofit organization and the donors.
      </li>
      <li className="mt-3" style={{ fontWeight: "800" }}>
        What are the methods nonprofits use to raise funds?
      </li>
      <li className="mt-3">
        The number of fundraising methods and possibilities available today are
        endless thanks to modern technology and the ability to reach prospective
        donors globally. Previously, nonprofit organizations needed to reach out
        to donors by telephone, by sending donation forms and envelopes, and by
        holding events in person.{" "}
      </li>
      <li className="mt-3">
        Times have changed drastically since then. We examine some of the most
        popular fundraising methods used by nonprofit organizations today.
      </li>
      <li className="mt-3" style={{ fontWeight: "800" }}>
        Fundraising via the Internet (Virtually)
      </li>
      <li className="mt-3">
        Virtual fundraising refers to a fundraiser that takes place virtually,
        whether that is via Zoom, Facebook Live, or another method. There are
        many types of virtual fundraising events to choose from, from virtual
        galas and concerts to trivia nights.
      </li>
      <li className="mt-3" style={{ fontWeight: "800" }}>
        Raising Money Online
      </li>
      <li className="mt-3">
        The process of fundraising online involves posting a fundraising
        campaign on your nonprofit's website, advertising that fundraising
        event, and watching the donations come in. People with busy schedules
        can make a financial contribution while they're at home or on the go
        because this type of fundraising is simple for them.{" "}
      </li>
      <li className="mt-3" style={{ fontWeight: "800" }}>
        Organizing Fundraisers
      </li>
      <li className="mt-3">
        To host a successful event fundraiser, you must first select what sort
        of event you want to hold (e.g., a bake sale, a rummage sale, a concert,
        picnic, dinner and entertainment evening, etc.). Additional cash can be
        raised at the event through games and ticket draws silent auctions and
        other activities you devise.
      </li>
      <li className="mt-3" style={{ fontWeight: "800" }}>
        Raising Funds via Auction
      </li>
      <li className="mt-3">
        Giving your donors something valuable in exchange for donations is an
        excellent way to solicit donations. You may find for-profit companies
        willing to offer a service in your auction. The prize could be something
        as simple as a massage or a night at a resort, to something as
        extravagant as jewellery or fine wines.{" "}
      </li>
      <li className="mt-3" style={{ fontWeight: "800" }}>
        Fundraising Using Peer-to-Peer Networks
      </li>
      <li className="mt-3">
        Nonprofits have unique fundraising opportunities now that they can
        leverage social media. P2P fundraising, for example, is one of these
        opportunities. People raise funds from each other for nonprofits through
        peer-to-peer fundraising.{" "}
      </li>
      <li className="mt-3" style={{ fontWeight: "800" }}>
        The Fundraising of Major Gifts
      </li>
      <li className="mt-3">
        Donations of over a thousand dollars are usually considered major gifts.
        A nonprofit organization typically receives a large portion of its
        donations from these sources. You may donate as an individual, as a
        corporation, or as a legacy.{" "}
      </li>
      <li className="mt-3" style={{ fontWeight: "800" }}>
        Fundraising through Recurring Donations
      </li>
      <li className="mt-3">
        Contributions made regularly are referred to as recurring donations.
        Recurring donations can assist your organization in preparing its
        budget.{" "}
      </li>
      <li className="mt-3" style={{ fontWeight: "800" }}>
        Raising Money through Merchandise
      </li>
      <li className="mt-3">
        Special merchandise is one of the most popular ways to raise money.
        Using the proceeds of the sale of goods, the organization achieves its
        mission. You can also set up an online store on your website and sell
        your products there if setting up a physical store is not possible for
        your organization.
      </li>
      <li className="mt-3" style={{ fontWeight: "800" }}>
        What do Non-Profits Need to Know?
      </li>
      <li className="mt-3">
        It's tempting for nonprofit leaders in our current economic climate to
        seek money from wherever they can, which leads some of them off course.
        A nonprofit's funding strategy must be examined closely during tough
        economic times, and it needs to be disciplined in the way it raises
        funds.{" "}
      </li>
      <li className="my-3">
        <span style={{ fontWeight: "700", fontStyle: "italic" }}>
          {" "}
          9th block{" "}
        </span>
        is, here, taking the initiative to help non-profits get the right kinds
        and amounts of funds by guiding them through the entire process of
        fundraising. To realize society's aspirations, a realistic understanding
        of funding models is essential as the nonprofit sector and philanthropy
        take up the challenge of solving important problems.{" "}
      </li>
    </div>
  );
}

export default NonProfitFundraising;
