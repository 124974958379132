import React from "react";
import { Col, Row } from "react-bootstrap";
import h1 from "../../img/h1.png";
import h2 from "../../img/h2.png";
import h3 from "../../img/h3.png";
import h4 from "../../img/h4.png";

const HomePageNew = () => {
  return (
    <div className="padding-side">
      <Row className="mx-0 mt-5">
        <Col xs={12} lg={6} className="mt-5 d-flex align-items-center">
          <div className="padding-side">
            <li
              className="whatsapp"
              style={{ fontSize: "2rem", maxWidth: "500px" }}
            >
              Fundraising simplified
            </li>
            <li>
              We help non-profit organizations meet their fundraising goals by
              connecting organizations to the right donors using our expertise,
              knowledge, and network.
            </li>
          </div>
        </Col>
        <Col xs={12} lg={6} className="mt-5">
          <div className="text-center">
            <img
              src={h1}
              style={{ maxWidth: "300px", maxHeight: "300px" }}
              alt=""
            />
          </div>
        </Col>
      </Row>

      <Row className="mx-0 mt-5">
        <Col xs={12} lg={6} className="mt-5 only-web">
          <div className="text-center">
            <img
              src={h2}
              alt=""
              style={{ maxWidth: "400px", maxHeight: "300px" }}
            />
          </div>
        </Col>
        <Col xs={12} lg={6} className="mt-5 d-flex align-items-center">
          <div className="padding-side">
            <li
              className="whatsapp"
              style={{ fontSize: "2rem", maxWidth: "500px" }}
            >
              Technology as a service
            </li>
            <li>
              Choose the right technology and tools for high productivity and
              efficiency to enable your organization to maximize your social
              impact goals.
            </li>
          </div>
        </Col>
        <Col xs={12} lg={6} className="mt-5 only-mob">
          <div className="text-center">
            <img
              src={h2}
              alt=""
              style={{ maxWidth: "400px", maxHeight: "300px" }}
            />
          </div>
        </Col>
      </Row>

      <Row className="mx-0 mt-5">
        <Col xs={12} lg={6} className="mt-5 d-flex align-items-center">
          <div className="padding-side">
            <li
              className="whatsapp"
              style={{ fontSize: "2rem", maxWidth: "500px" }}
            >
              Deep Impact analysis and reporting
            </li>
            <li>
              We understand the value of reporting in raising continuous funds,
              but that takes a lot of effort and time. We have automated that so
              you can focus on more critical tasks.
            </li>
          </div>
        </Col>
        <Col xs={12} lg={6} className="mt-5">
          <div className="text-center">
            <img
              src={h3}
              alt=""
              style={{ maxWidth: "300px", maxHeight: "300px" }}
            />
          </div>
        </Col>
      </Row>

      <Row className="mx-0 my-5">
        <Col xs={12} lg={6} className="mt-5 only-web">
          <div className="text-center">
            <img
              src={h4}
              alt=""
              style={{ maxWidth: "400px", maxHeight: "300px" }}
            />
          </div>
        </Col>
        <Col xs={12} lg={6} className="mt-5 d-flex align-items-center">
          <div className="padding-side">
            <li
              className="whatsapp"
              style={{ fontSize: "2rem", maxWidth: "500px" }}
            >
              Finance Consulting
            </li>
            <li>
              We consult and train in the areas of grant writing, FCRA
              compliance, and financial auditing.
            </li>
          </div>
        </Col>
        <Col xs={12} lg={6} className="mt-5 only-mob">
          <div className="text-center">
            <img
              src={h4}
              alt=""
              style={{ maxWidth: "400px", maxHeight: "300px" }}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default HomePageNew;
