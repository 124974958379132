import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import laptop from "../../img/laptop.png";
// import mobile from "../../img/mobile.png";
import mobile1 from "../../img/mobile1.png";
import edgeEffect from "../../img/edge-effect.png";
import tx from "../../img/prod-tx.png";
import AOS from "aos";
import Feature from "./Feature";
import Header from "../Header";
import { Link } from "react-router-dom";
import mainPicEffect from "../../img/main-pic-effect.png";
import prod1 from "../../img/prod1.png";
import prod2 from "../../img/prod2.png";
import EdgeBenefit from "./EdgeBenefit";
import EdgeFeature from "./EdgeFeature";

function Edge() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  return (
    <>
      <Header />
      {/* <div className="product">
        <div className="hue prod-img"></div>
      
      </div>
      <img src={tx} className="mb-5 padding-side only-web" width="60%" alt="" />
      <img
        src={tx}
        className="mb-5 mx-4 only-mob text-center"
        width="100%"
        alt=""
      /> */}

      <>
        <Row className="mx-0 d-flex align-items-center main-flex-lg padding-side">
          <Col className="px-0">
            <div className="header-marg">
              <li className="home-problem-title">One platform for </li>
              <li
                className="home-problem-title font-fam"
                style={{ color: "#4EAEBB" }}
              >
                project & user management
              </li>
              <li className="mt-3" style={{ fontWeight: "550" }}>
                Helps people to drive more collaboration between the NGO,
                Corporates and beneficiaries. We improve the transparency in CSR
                investment.
              </li>
              <Link to="/contact">
                <button className="btn-start mt-5">Request for demo</button>
              </Link>
            </div>
          </Col>
          <Col className="px-0 text-center">
            <div style={{ position: "relative", height: "400px", top: "50px" }}>
              <img
                src={mainPicEffect}
                className=""
                style={{ position: "relative", left: "4rem", bottom: "4rem" }}
                alt=""
              />
              <img src={prod1} className="edge-prod-img " alt="" />
              <img
                src={prod2}
                className="edge-prod-img edge-prod-img2"
                alt=""
              />
            </div>
          </Col>

          {/* <Col xs={12} lg={6}>
            <div>
              <li className="block">9thBlock</li>
              <li className="edge">Edge</li>
              <li className="home-problem-title2 mt-4">
                The purpose of the edge platform is to drive more collaboration
                between the NGO, Corporates and beneficiaries and we improve the
                transparency in CSR investment. Our product allows NGOs to run
                effectively and manage their programs, to have stronger
                collaboration, and increase transparency of CSR between the
                donors and NGOs. Our platform helps NGO in getting funds.
              </li>
              <img src={edgeEffect} className="edge-effect" alt="" />
              <img
                src={laptop}
                data-aos={"fade-right"}
                className="laptop mt-5"
                alt=""
                width="100%"
              />
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="text-center mobile-grd edge-mob only-web">
              <img
                src={mobile1}
                alt=""
                data-aos={"fade-left"}
                data-aos-offset={"200"}
                style={{ marginTop: "-140px" }}
              />
            </div>
            <div className="text-center mobile-grd edge-mob only-mob">
              <img
                src={mobile1}
                alt=""
                data-aos={"fade-left"}
                data-aos-offset={"200"}
                style={{ marginTop: "-180px" }}
                className="mob-transform"
              />
            </div>
          </Col> */}
        </Row>
        <div
          className="padding-side mt-4"
          style={{
            backgroundColor: "#053F5A",
            color: "#fff",
            paddingTop: "5rem",
            paddingBottom: "5rem",
          }}
        >
          <li className="text-center">
            The purpose of the edge platform is to drive more collaboration
            between the NGO, Corporates, and beneficiaries and we improve the
            transparency in CSR investment. Our product allows NGOs to run
            effectively and manage their programs, to have stronger
            collaboration, and increase the transparency of CSR between the
            donors and NGOs. Our platform helps NGOs in getting funds.
          </li>
        </div>
        <EdgeBenefit />
        <EdgeFeature />
        {/* <Feature /> */}
        {/* <div className="prod-more more">
          <div className="more-colr">
            <Link to="/contact">
              <button className="btn-start" style={{ padding: "20px 50px" }}>
                Know more about the product
              </button>
            </Link>
          </div>
        </div> */}
      </>
    </>
  );
}

export default Edge;
