import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import effect1 from "../../img/resource-effect.png";
import tx from "../../img/event-tx.png";
import Webinar from "./Webinar";
import UpcomingEvent from "./UpcomingEvent";
import Header from "../Header";

function Event() {
  const [isBlog, setBlog] = useState(1);

  const onBlog = (i) => {
    setBlog(i);
  };

  return (
    <>
      <Header />
      <div className="header-marg">
        <div className="product">
          <div className="hue event-img"></div>
          {/* <img src={productImg} alt="" /> */}
        </div>
        <img
          src={tx}
          className="mb-5 padding-side only-web"
          width="60%"
          alt=""
        />
        <img
          src={tx}
          className="mb-5 mx-4 only-mob text-center"
          width="100%"
          alt=""
        />

        <Row className="mx-0 padding-side mt-4 ">
          <Col xs={12} lg={3} className="only-web">
            <div className="mt-5">
              <li
                className={`contact-trial mt-3 ${isBlog === 1 ? "colr" : null}`}
                onClick={() => onBlog(1)}
              >
                Upcoming events
              </li>
              {/* <hr style={{ width: "140px" }} />
            <li
              className={`contact-trial mt-3 ${isBlog === 2 ? "colr" : null}`}
              onClick={() => onBlog(2)}
            >
              Webinar
            </li> */}
              {/* <hr style={{ width: "140px" }} /> */}
              {/* <li
              className={`contact-trial mt-3 ${isBlog === 3 ? "colr" : null}`}
              onClick={() => onBlog(3)}
            >
              Podcast
            </li> */}
              <img src={effect1} className="edge-effect" alt="" />
            </div>
          </Col>
          <Col xs={12} lg={3} className="only-mob">
            <div className="mt-5 d-flex justify-content-between">
              <li
                className={`contact-trial mt-3 ${isBlog === 1 ? "colr" : null}`}
                onClick={() => onBlog(1)}
              >
                Upcoming events
              </li>
              <li
                className={`contact-trial mt-3 ${isBlog === 2 ? "colr" : null}`}
                onClick={() => onBlog(2)}
              >
                Webinar
              </li>

              <li
                className={`contact-trial mt-3 ${isBlog === 3 ? "colr" : null}`}
                onClick={() => onBlog(3)}
              >
                Podcast
              </li>
            </div>
          </Col>
          <Col xs={12} lg={9} className="">
            <div className={`${isBlog === 1 ? "d-block" : "d-none"}`}>
              <UpcomingEvent />
            </div>
            <div className={`${isBlog === 2 ? "d-block" : "d-none"}`}>
              <Webinar />
            </div>
            {/* <div className={`${isBlog === 2 ? "d-block" : "d-none"}`}>
            <Partnership />
          </div> */}
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Event;
