import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import effect1 from "../../img/sol-effect.png";
import effect2 from "../../img/sol-effect2.png";
import tx from "../../img/sol-tx.png";
import AOS from "aos";
import sol1 from "../../img/sol1.png";
import sol2 from "../../img/sol2.png";
import sol3 from "../../img/sol3.png";

function SolutionPage() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <div>
      <div className="product">
        <div className="hue sol-img"></div>
        {/* <img src={solutionImg} alt="" /> */}
      </div>
      <img src={tx} className="mb-5 padding-side only-web" width="60%" alt="" />
      <img
        src={tx}
        className="mb-5 mx-3 only-mob text-center"
        width="100%"
        alt=""
      />
      <Row className="mx-0 padding-side mt-5">
        <Col xs={12} lg={6}>
          <div data-aos={"fade-right"}>
            <li className="benefit">Digital Volunteering Platform</li>
            <li className="home-problem-title2 mt-2">
              In recent years, technology has become a driving force for
              economic and social change. It is not uncommon for non-profit
              making organizations (NGOs) to recruit volunteers without relying
              heavily on ICT (information and digital technology). To attract
              the most well-prepared and motivated volunteers, organizations in
              this sector should incorporate and use digital platforms. The
              perception of a technological platform is to give non-profits and
              volunteers access to information, tools, and opportunities.
            </li>
            <a href="/solution/digital-volunteer">
              <li className="sol-read mt-3">Read more...</li>
            </a>
          </div>
        </Col>
        <Col xs={12} lg={6}>
          <img src={effect2} className="home-problem-effect" alt="" />
          <div className="text-center">
            <img src={sol1} className="sol-blog" data-aos={"zoom-in"} alt="" />
          </div>
        </Col>
      </Row>

      <div className="sol-bg">
        <img src={effect1} className="edge-effect only-web" alt="" />
        <Row className="mx-0 padding-side" style={{ paddingTop: "100px" }}>
          <Col xs={12} lg={6} className="only-web">
            <div className="text-center mt-5">
              <img
                src={sol2}
                className="sol-blog"
                data-aos={"zoom-in"}
                alt=""
              />
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="" data-aos={"fade-left"}>
              <li className="benefit">CSR Investment Management</li>
              <li className="home-problem-title2 mt-2">
                Donating time or materials to a charity or non-profit
                organization is the act of an insurance or investment group
                doing this to promote social wellbeing and not just to meet its
                own or shareholders' interests. The concept of CSR is becoming
                more integrated into how businesses operate since they must
                demonstrate the positive impact they are having on society, the
                environment, and their employees.
              </li>
              <a href="/solution/csr-investment">
                <li className="sol-read mt-3">Read more...</li>
              </a>
            </div>
          </Col>
          <Col xs={12} lg={6} className="only-mob">
            <div className="text-center mt-2">
              <img
                src={sol2}
                className="sol-blog"
                data-aos={"zoom-in"}
                alt=""
              />
            </div>
          </Col>
        </Row>
      </div>

      <Row className="mx-0 padding-side sol-sm-top">
        <Col xs={12} lg={6}>
          <div data-aos={"fade-right"}>
            <li className="benefit">Technology for Non-Profits</li>
            <li className="home-problem-title2 mt-2">
              A lot has happened to this world over the last two or so decades,
              but one of the greatest events in technological development.
              Technology which is fundamental to making our lives better and
              more fulfilling has altered the world in a variety of ways. The
              positive influence of technology on globalisation continues to be
              seen. With technology at the centre of any business plan these
              days, the question isn't so much if you have it as when you should
              acquire it. We need to figure out how to make this information
              more accessible and inexpensive for sectors that need it more than
              ever.
            </li>
            <a href="/solution/tech-for-non-profit">
              <li className="sol-read mt-3">Read more...</li>
            </a>
          </div>
        </Col>
        <Col xs={12} lg={6}>
          <div className="text-center">
            <img src={sol3} className="sol-blog" data-aos={"zoom-in"} alt="" />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default SolutionPage;
