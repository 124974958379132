import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import effect1 from "../../img/contact-effect.png";
import effect2 from "../../img/contact-effect2.png";
import tx from "../../img/contact-tx.png";
import getStart from "../../img/contact-start.png";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Header from "../Header";

function Contact() {
  const [isBlog, setBlog] = useState(1);

  const onBlog = (i) => {
    setBlog(i);
  };

  return (
    <>
      <Header />
      <div className="header-marg">
        <div className="product">
          <div className="hue contact-img"></div>
          {/* <img src={contactImg} className="hue" alt="" /> */}
        </div>
        <img
          src={tx}
          className="mb-5 padding-side only-web"
          width="60%"
          alt=""
        />
        <img
          src={tx}
          className="mb-5 mx-4 only-mob text-center"
          width="90%"
          alt=""
        />
        <img src={effect1} className="card-effect" alt="" />
        <Row className="mx-0 padding-side">
          <Col xs={12} lg={6} className="only-web">
            <div className="mt-5">
              <li
                className={`contact-trial mt-3 ${isBlog === 1 ? "colr" : null}`}
                onClick={() => onBlog(1)}
              >
                Free Trial
              </li>
              <hr style={{ width: "140px" }} />
              <li
                className={`contact-trial mt-3 ${isBlog === 2 ? "colr" : null}`}
                onClick={() => onBlog(2)}
              >
                Get Started
              </li>
              <hr style={{ width: "140px" }} />
              <li
                className={`contact-trial mt-3 ${isBlog === 3 ? "colr" : null}`}
                onClick={() => onBlog(3)}
              >
                Know more about Products
              </li>
              <hr style={{ width: "140px" }} />
              <li
                className={`contact-trial mt-3 ${isBlog === 4 ? "colr" : null}`}
                onClick={() => onBlog(4)}
              >
                Join Partnership Program
              </li>
            </div>
            <img src={effect2} className="edge-effect only-web" alt="" />
          </Col>
          <Col xs={12} lg={6} className="only-web">
            <div className={`${isBlog === 1 ? "d-block" : "d-none"}`}>
              <Trial />
            </div>
            <div className={`${isBlog === 2 ? "d-block" : "d-none"}`}>
              <GetStarted />
            </div>
            <div className={`${isBlog === 3 ? "d-block" : "d-none"}`}>
              <AboutProduct />
            </div>
            <div className={`${isBlog === 4 ? "d-block" : "d-none"}`}>
              <JoinPartnership />
            </div>
          </Col>

          <div className="only-mob">
            <Accordion defaultExpanded={true} className="accord-marg">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className={`home-pending-accord-heading `}>Free Trial</div>
              </AccordionSummary>
              <hr style={{ width: "100%", margin: "0" }} />
              <AccordionDetails>
                <div className="w-100">
                  <Trial />
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={false} className="accord-marg">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className={`home-pending-accord-heading `}>
                  {" "}
                  Get Started
                </div>
              </AccordionSummary>
              <hr style={{ width: "100%", margin: "0" }} />
              <AccordionDetails>
                <div className="w-100">
                  <GetStarted />
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={false} className="accord-marg">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className={`home-pending-accord-heading `}>
                  Know more about Products
                </div>
              </AccordionSummary>
              <hr style={{ width: "100%", margin: "0" }} />
              <AccordionDetails>
                <div className="w-100">
                  <AboutProduct />
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={false} className="accord-marg">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className={`home-pending-accord-heading `}>
                  Join Partnership Program
                </div>
              </AccordionSummary>
              <hr style={{ width: "100%", margin: "0" }} />
              <AccordionDetails>
                <div className="w-100">
                  <JoinPartnership />
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </Row>

        <div className={`contact-start-bg `}>
          {/* <li
          className={`padding-side contact-start mb-5 ${
            isBlog === 1 ? "d-block" : "d-none"
          }`}
        >
          Start your free trial
        </li> */}
        </div>
      </div>
    </>
  );
}

export default Contact;

function Trial() {
  return (
    <div className="contact-card p-4">
      <form
        action="https://docs.google.com/forms/d/e/1FAIpQLScUugRivFQKM4kdLfq2zIyvWqh3qghi0HOzwxxb0fH5TNlZqw/formResponse"
        method="POST"
      >
        <input type="text" name="entry.1947239950" placeholder="Name" />
        <input
          type="text"
          name="entry.1705330443"
          placeholder="Organisation name"
        />
        <input type="text" name="entry.751801802" placeholder="Email id" />
        <div className="d-flex align-items-center">
          <input
            type="checkbox"
            name="entry.1121440127"
            value="Edge"
            style={{ width: "30px", height: "30px" }}
          />
          <li className="mx-2">9thBlock Edge</li>
        </div>
        <div className="d-flex align-items-center">
          <input
            type="checkbox"
            name="entry.407713701"
            value="Hub"
            style={{ width: "30px", height: "30px" }}
          />
          <li className="mx-2">9thBlock Hub</li>
        </div>

        <div className="d-flex align-items-center">
          <input type="checkbox" style={{ width: "25px", height: "25px" }} />
          <li className="mx-2 mt-4">
            I have read and agreed to the Terms of Use and Privacy Policy
          </li>
        </div>
        <div className="text-center mt-5">
          <button
            className="btn-start"
            name="fbzx"
            style={{ padding: "10px 20px" }}
          >
            Start Free Trial
          </button>
        </div>
      </form>
    </div>
  );
}

function GetStarted() {
  return (
    <div className="contact-card p-4">
      <form
        action="https://docs.google.com/forms/d/e/1FAIpQLSf1q58ffDgWPWxtxt4Kt3hxaUQiYkMIve5SXgzXCdUWaFck4A/formResponse"
        method="POST"
      >
        <img src={getStart} width="100%" height="240px" alt="" />
        <input
          type="text"
          name="entry.1872434154"
          placeholder="Name"
          style={{ marginTop: "5px" }}
        />
        <input
          type="text"
          name="entry.1991680648"
          placeholder="Organisation name"
        />
        <input type="text" name="entry.547028220" placeholder="Email id" />

        <div className="text-center mt-2">
          <button
            className="btn-start"
            name="fbzx"
            style={{ padding: "10px 20px" }}
          >
            Get Started
          </button>
        </div>
      </form>
    </div>
  );
}

function AboutProduct() {
  return (
    <div className="contact-card p-4">
      <form
        action="https://docs.google.com/forms/d/e/1FAIpQLSfs2M-PZ5n1_b9fOR1t76xrhGuiPr3QgtSlFXrS-pW-RalHMQ/formResponse"
        method="POST"
      >
        <input type="text" name="entry.1146048098" placeholder="Name" />
        <input
          type="text"
          name="entry.1379317199"
          placeholder="Organisation name"
        />
        <input type="text" name="entry.964965936" placeholder="Email id" />
        <textarea
          name="entry.887331317"
          placeholder="Write to us.."
          style={{ height: "190px" }}
        />

        <div className="text-center mt-4">
          <button className="btn-start " style={{ padding: "10px 40px" }}>
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

function JoinPartnership() {
  return (
    <div className="contact-card p-4">
      <form
        action="https://docs.google.com/forms/d/e/1FAIpQLSc3miMnsyM-VFvQpxRwIG7L_-mRUpGv2c7YstChBwHCzktmZA/formResponse"
        method="POST"
      >
        <input type="text" name="entry.1795945341" placeholder="Name" />
        <input
          type="text"
          name="entry.1189969062"
          placeholder="Organisation name"
        />
        <input type="text" name="entry.913728638" placeholder="Email id" />
        <textarea
          name="entry.574776630"
          placeholder="Write to us.."
          style={{ height: "190px" }}
        />

        <div className="text-center mt-4">
          <button className="btn-start " style={{ padding: "10px 50px" }}>
            Join
          </button>
        </div>
      </form>
    </div>
  );
}
