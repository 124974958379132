import React from "react";
import { Col, Row } from "react-bootstrap";
// import e2 from "../../img/e2.png";

function UpcomingEvent() {
  return (
    <>
      <div className="home-event-card my-5 only-web">
        <Row className="mx-0 " style={{ height: "100%" }}>
          <Col className="px-0">
            <div className="">
              <li className="home-event-up blog-color px-4">Upcoming Blog</li>
              <li className="home-event-cont  px-4 py-2 mt-2">
                Non-Profit: Fundraising.
              </li>
              {/* <li className="home-event-cont2  px-4 py-2">
                Organization: Impact Academy by CSRBOX
              </li> */}
              <li className="px-4 " style={{ fontWeight: "600" }}>
                Publish on: 11th November, 21
              </li>
              <li className="home-event-cont3  px-4 pt-2">By: 9thBlock</li>
            </div>
            <div className="mx-4 py-4">
              <a href="/contact">
                <button className="btn-start" style={{ padding: "10px 50px" }}>
                  Register
                </button>
              </a>
            </div>
          </Col>
          <Col className="px-0">
            <div className="home-event-bg text-center"></div>
          </Col>
        </Row>
      </div>
      <div className="home-event-card my-5 only-web">
        <Row className="mx-0 " style={{ height: "100%" }}>
          <Col className="px-0">
            <div className="">
              <li className="home-event-up podcast-color px-4">
                Upcoming Podcast
              </li>
              <li className="home-event-cont  px-4 py-2 mt-2">Fundraising</li>
              {/* <li className="home-event-cont2  px-4 py-2">
                Organization: Impact Academy by CSRBOX
              </li> */}
              <li className="px-4 " style={{ fontWeight: "600" }}>
                Guest Speaker : Jason Medlycott Head of Fundraising &
                Communication Manager from Pardada Pardadi Educational Society
              </li>
              <li className="home-event-cont3  px-4 pt-2">Coming soon</li>
            </div>
            <div className="mx-4 py-4">
              <a href="/contact">
                <button className="btn-start" style={{ padding: "10px 50px" }}>
                  Register
                </button>
              </a>
            </div>
          </Col>
          <Col className="px-0">
            <div className="home-event-bg home-event-bg2 text-center"></div>
            {/* <div>
              <img src={e2} width="100%" height="100%" alt="" />
            </div> */}
          </Col>
        </Row>
      </div>

      <div className="home-event-card  only-mob blog-mob-top">
        <Row className="mx-0 " style={{ height: "100%" }}>
          <Col md={12} className="px-0">
            <div className="">
              <li className="home-event-up blog-color px-4">Upcoming Blog</li>
              <li className="home-event-cont  px-3 py-2 mt-2 text-start">
                Non-Profit: Fundraising.
              </li>
              {/* <li className="home-event-cont2  px-3 py-2 text-start">
                Organization: Impact Academy by CSRBOX
              </li> */}
              <li
                className="px-3 py-2 text-start"
                style={{ fontWeight: "600" }}
              >
                Publish on: 11th November, 21
              </li>
              <li className="home-event-cont3  px-3 py-2 text-start">
                By: 9thBlock
              </li>
            </div>
          </Col>
          <Col md={12} className="px-0">
            <div className="home-event-bg text-center">
              <a href="/event/webinar">
                <button
                  className="btn-start"
                  style={{
                    marginTop: "150px",
                    padding: "10px 40px",
                    marginBottom: "20px",
                  }}
                >
                  Know more
                </button>
              </a>
            </div>
          </Col>
        </Row>
      </div>
      <div className="home-event-card  only-mob mt-5">
        <Row className="mx-0 " style={{ height: "100%" }}>
          <Col md={12} className="px-0">
            <div className="">
              <li className="home-event-up podcast-color px-4">
                Upcoming Podcast
              </li>
              <li className="home-event-cont  px-3 py-2 mt-2 text-start">
                Fundraising
              </li>
              <li
                className="px-3 py-2 text-start"
                style={{ fontWeight: "600" }}
              >
                Guest Speaker : Jason Medlycott Head of Fundraising &
                Communication Manager from Pardada Pardadi Educational Society
              </li>
              <li className="home-event-cont3  px-3 py-2 text-start">
                Coming soon
              </li>
            </div>
          </Col>
          <Col md={12} className="px-0">
            <div className="home-event-bg text-center">
              <a href="/event/webinar">
                <button
                  className="btn-start"
                  style={{
                    marginTop: "150px",
                    padding: "10px 40px",
                    marginBottom: "20px",
                  }}
                >
                  Know more
                </button>
              </a>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default UpcomingEvent;
