import React from "react";
import { Col, Row } from "react-bootstrap";

const HomeCardNew = () => {
  return (
    <div>
      <Row className="mx-0">
        <Col xs={12} lg={8} className="px-0">
          <div className="home-card-new-bg padding-side py-5">
            <li className="home-title" style={{ color: "#fff" }}>
              Empowering organizations
            </li>
            <li className="home-title2 mt-4" style={{ color: "#fff" }}>
              to drive their social impact goals leveraging the power of
              technology
            </li>
            <a href="/">
              <button className="btn-visit mt-4">Visit Website</button>
            </a>
          </div>
        </Col>
        <Col xs={12} lg={4} className="px-0 ">
          <div className="home-card-new-bg2 py-5">
            <li
              className="mb-4"
              style={{ fontSize: "2rem", color: "#fff", fontWeight: "700" }}
            >
              To know more
            </li>
            <input type="text" placeholder="Name" />
            <input type="text" placeholder="Email" />
            <input type="text" placeholder="Organisation name" />
            <textarea placeholder="Write to us.."></textarea>
            <div className="mt-3">
              <button className="btn-submit">Submit</button>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default HomeCardNew;
