import React from "react";
import { Col, Row } from "react-bootstrap";
import Header from "../Header";
import hub1 from "../../img/mobile.png";
import eb1 from "../../img/eb2.png";
import eb2 from "../../img/eb3.png";

const HubNew = () => {
  return (
    <div className="header-marg padding-side">
      <Header />
      <div className="text-center py-4">
        <li style={{ fontSize: "1.6rem", fontWeight: "800" }}>Hub</li>
        <li className="mt-1">
          The new-age, digital platform for deeper collaboration, networking and
          need mapping among stakeholders in the community impact ecosystem.
        </li>
      </div>

      <Row className="mx-0">
        <Col xs={12} lg={6} className="mt-3">
          <div>
            <li style={{ fontSize: "1.2rem", fontWeight: "600" }}>Features:</li>
            <li
              className="mt-3"
              style={{ fontSize: "1.08rem", fontWeight: "600" }}
            >
              Registration
            </li>
            <li>
              Setup Profiles with updated impact, financial, and annual reports.
              Add team members, responsible for networking, to the platform and
              keep conversations targeted
            </li>

            <li
              className="mt-3"
              style={{ fontSize: "1.08rem", fontWeight: "600" }}
            >
              Circles
            </li>
            <li>
              Join circles relevant to project or funding needs for focused
              collaborations
            </li>

            <li
              className="mt-3"
              style={{ fontSize: "1.08rem", fontWeight: "600" }}
            >
              Request Board
            </li>
            <li>
              Share requests along with media files for fundraising, project
              associations or volunteer requirements
            </li>

            <li
              className="mt-3"
              style={{ fontSize: "1.08rem", fontWeight: "600" }}
            >
              Messages & Posts
            </li>
            <li>
              Connect with interested donors through private messaging for
              secure communication. Archive requests once they are fulfilled.
            </li>

            <li
              className="mt-3"
              style={{ fontSize: "1.08rem", fontWeight: "600" }}
            >
              Certificate of Appreciation
            </li>
            <li>
              Show love to contributors by thanking them for their social impact
              efforts
            </li>

            <li
              className="mt-3"
              style={{ fontSize: "1.08rem", fontWeight: "600" }}
            >
              Past Donors
            </li>
            <li>
              Maintain a ready list of previous donors and associations for
              simplified reporting
            </li>
          </div>
        </Col>
        <Col xs={12} lg={6} className="mt-3">
          <div className="position-relative">
            <img src={hub1} alt="" style={{ width: "100%", height: "100%" }} />
            {/* <img
              src={prod2}
              alt=""
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                left: "10%",
                bottom: "-50%",
              }}
            /> */}
          </div>
        </Col>
      </Row>

      <Row className="mx-0 my-5">
        <Col xs={12} lg={6} className="mt-3">
          <div className="position-relative">
            <img src={eb1} alt="" style={{ width: "100%", height: "100%" }} />
            <img
              src={eb2}
              alt=""
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                left: "10%",
                bottom: "-20%",
              }}
            />
          </div>
        </Col>
        <Col xs={12} lg={6} className="mt-3">
          <div>
            <li style={{ fontSize: "1.2rem", fontWeight: "600" }}>Benefits:</li>
            <li
              className="mt-3"
              style={{ fontSize: "1.08rem", fontWeight: "600" }}
            >
              Seamless Communication
            </li>
            <li>
              Democratizes communication between Donors and NGOs by setting up
              uniform practices and two-way vetting processes to address
              stakeholder needs
            </li>

            <li
              className="mt-3"
              style={{ fontSize: "1.08rem", fontWeight: "600" }}
            >
              Quicker Collaboration
            </li>
            <li>
              Decreases the time spent in finding project collaborators, and
              enables sharing of surplus resources for a good cause faster.
            </li>

            <li
              className="mt-3"
              style={{ fontSize: "1.08rem", fontWeight: "600" }}
            >
              Building Visibility
            </li>
            <li>
              Donors have better visibility about NGOs with reports available at
              a glance, and NGOs reach a broader audience showcasing their
              brand.
            </li>

            <li
              className="mt-3"
              style={{ fontSize: "1.08rem", fontWeight: "600" }}
            >
              Equal Access
            </li>
            <li>
              Stakeholders, big and small, get equal access to community impact
              associations, meet like-minded organizations and fulfill social
              impact needs quicker.
            </li>

            <li
              className="mt-3"
              style={{ fontSize: "1.08rem", fontWeight: "600" }}
            >
              Resource Management
            </li>
            <li>
              Donors and stakeholders can direct surplus resources, in kind, to
              communities in need of them most.
            </li>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default HubNew;
