import React from "react";
import { Col, Row } from "react-bootstrap";
import Header from "../Header";
import prod1 from "../../img/prod1.png";
import prod2 from "../../img/prod2.png";
import eb1 from "../../img/eb2.png";
import eb2 from "../../img/eb3.png";

const EdgeNew = () => {
  return (
    <div className="header-marg padding-side">
      <Header />
      <div className="text-center py-4">
        <li style={{ fontSize: "1.6rem", fontWeight: "800" }}>Edge</li>
        <li className="mt-1">
          New age, one-stop social program platform for collaboration,
          communication and deep impact analysis, driving transparency between
          stakeholders
        </li>
      </div>

      <Row className="mx-0">
        <Col xs={12} lg={6} className="mt-3">
          <div>
            <li style={{ fontSize: "1.2rem", fontWeight: "600" }}>Features:</li>
            <li
              className="mt-3"
              style={{ fontSize: "1.08rem", fontWeight: "600" }}
            >
              Dedicated Dashboard
            </li>
            <li>
              Personalized dashboard for users with different levels of
              responsibilities
            </li>

            <li
              className="mt-3"
              style={{ fontSize: "1.08rem", fontWeight: "600" }}
            >
              Task Management
            </li>
            <li>
              Assign and track tasks, to engage and guide users with purpose
            </li>

            <li
              className="mt-3"
              style={{ fontSize: "1.08rem", fontWeight: "600" }}
            >
              Discussions
            </li>
            <li>
              Encourage real-time collaboration between teams via focused
              discussions
            </li>

            <li
              className="mt-3"
              style={{ fontSize: "1.08rem", fontWeight: "600" }}
            >
              Impact Showcase
            </li>
            <li>
              Collect data, beneficiary stories and testimonials to measure and
              present your outcomes
            </li>

            <li
              className="mt-3"
              style={{ fontSize: "1.08rem", fontWeight: "600" }}
            >
              Reports
            </li>
            <li>
              Stay in the know via activity analytics and progress reports
            </li>

            <li
              className="mt-3"
              style={{ fontSize: "1.08rem", fontWeight: "600" }}
            >
              Resources{" "}
            </li>
            <li>
              Share training resources, videos, reports, and more with users
            </li>

            <li
              className="mt-3"
              style={{ fontSize: "1.08rem", fontWeight: "600" }}
            >
              Badges
            </li>
            <li>Celebrate impact success and wins with the community</li>

            <li
              className="mt-3"
              style={{ fontSize: "1.08rem", fontWeight: "600" }}
            >
              Engagement Arena
            </li>
            <li>
              Bring users together using descriptive profiles and shared updates
            </li>
          </div>
        </Col>
        <Col xs={12} lg={6} className="mt-3">
          <div className="position-relative">
            <img src={prod1} alt="" style={{ width: "100%", height: "100%" }} />
            <img
              src={prod2}
              alt=""
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                left: "10%",
                bottom: "-50%",
              }}
            />
          </div>
        </Col>
      </Row>

      <Row className="mx-0 my-5">
        <Col xs={12} lg={6} className="mt-3">
          <div className="position-relative">
            <img src={eb1} alt="" style={{ width: "100%", height: "100%" }} />
            <img
              src={eb2}
              alt=""
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                left: "10%",
                bottom: "-20%",
              }}
            />
          </div>
        </Col>
        <Col xs={12} lg={6} className="mt-3">
          <div>
            <li style={{ fontSize: "1.2rem", fontWeight: "600" }}>Benefits:</li>
            <li
              className="mt-3"
              style={{ fontSize: "1.08rem", fontWeight: "600" }}
            >
              Less is more
            </li>
            <li>
              Simplifies manual processes such as feedback gathering, training,
              and reporting through integrated information in one place.
            </li>

            <li
              className="mt-3"
              style={{ fontSize: "1.08rem", fontWeight: "600" }}
            >
              Continuous learning
            </li>
            <li>
              Improve continuously by analyzing program data, considering
              feedback and scaling sustainable processes.
            </li>

            <li
              className="mt-3"
              style={{ fontSize: "1.08rem", fontWeight: "600" }}
            >
              Better Transparency
            </li>
            <li>
              Users can view everything related to a project within the
              platform, including historical data and feedback.
            </li>

            <li
              className="mt-3"
              style={{ fontSize: "1.08rem", fontWeight: "600" }}
            >
              Deep Impact Analysis
            </li>
            <li>
              Understand project impact with beneficiary stories, goal
              monitoring, communication efficacy, real-time data analysis, and
              more.
            </li>

            <li
              className="mt-3"
              style={{ fontSize: "1.08rem", fontWeight: "600" }}
            >
              Employee Engagement
            </li>
            <li>
              Include employees in creating impact by championing digital and
              in-person contribution plus volunteering opportunities.
            </li>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default EdgeNew;
