import React from "react";
import sol2 from "../../img/sol2.png";
import { Col, Row } from "react-bootstrap";

function CsrInvestment() {
  return (
    <div className="mt-5 padding-side">
      <li className="" style={{ fontWeight: "800", fontSize: "1.2rem" }}>
        Corporate social responsibility (CSR) has become a catchphrase in the
        last few years, but what does it mean and why is it important to
        investors?
      </li>
      <Row className="mt-4">
        <Col xs={12} lg={6}>
          <li className="mt-3">
            Donating time or materials to a charity or non-profit organization
            is the act of an insurance or investment group doing this to promote
            social wellbeing and not just to meet its own or shareholders'
            interests. The concept of CSR is becoming more integrated into how
            businesses operate since they must demonstrate the positive impact
            they are having on society, the environment, and their employees.
          </li>
          <li className="mt-3">
            A lot of attention is being paid to investments in CSR activities.
            Before making full-time collaborations, investors need to understand
            whether or not these investments positively impact the profitability
            of the firm, boards and shareholders. There has been an increase in
            the demand of accountability from the shareholders on the outcomes
            of these projects.
          </li>
          <li className="mt-3">
            CSR research that examines how CSR impacts profitability is
            particularly relevant because customers are essential among business
            stakeholders. CSR enhances customer loyalty, increases willingness
            to pay premium prices, and lowers reputational risks in crises. This
            in turn enables organizations to increase their profitability.
          </li>
        </Col>
        <Col xs={12} lg={6}>
          <img src={sol2} alt="" />
        </Col>
      </Row>

      <li className="mt-3">
        It is common for the relationship between CSR activities and financial
        performance to be mediated by many other variables. Companies' CSR
        activities have traditionally focused on charitable giving and helping
        local communities such as schools and nursing homes. These help
        investors to seek the right investment management amongst CSRs or
        non-profits.
      </li>
      <li className="mt-3">
        In today's business world, CSR is becoming a fundamental component of
        how a company operates. Organizations must demonstrate to customers,
        employees, and society what they are doing to meet their needs. The
        issue of corporate social responsibility is becoming more important to
        investors because they are more concerned with where their money is
        invested.
      </li>
      <li className="mt-3">
        A company now considers its impact on the world in addition to its
        profit and loss as a whole. CSR, until recently, was mostly considered a
        marketing tactic to pad out annual reports, but consumers today expect
        more authentic programs.
      </li>
      <li className="mt-3">
        Business strategy has become increasingly integrated with CSR - covering
        business interactions with society as a whole and the environment in
        particular. Corporate Social Responsibility is about going beyond legal
        obligations and putting social and environmental impact at the core of
        the business for long-term success.
      </li>
      <li className="mt-3">
        Different investors view CSR in slightly different ways, however, the
        essence of CSR remains the same. Our understanding of CSR is that of a
        commitment by the company to help improve the social and environmental
        conditions of those impacted by several issues. It does not include
        activities that are designed to meet corporate goals and comply with
        legal authority.
      </li>
      <li className="mt-3">
        Typically, CSR is devoted to influencing the lives of external
        stakeholders, including communities, and not to promoting internal
        concerns such as employee welfare or resource management.
      </li>
      <li className="mt-3 text-center" style={{ fontWeight: "800" }}>
        In what way does it matter?
      </li>
      <li className="mt-3">
        CSR-oriented companies prioritize transparency and aim to communicate
        their CSR initiatives to a broad range of stakeholders. Information is
        important since it helps us distinguish between actors who talk a good
        game from those who deliver results.
      </li>
      <li className="mt-3">
        A corporate social responsibility policy emphasizes the significance of
        non-financial issues in a company's valuation, as evidenced by a senior
        management team that understands them. Business success often relies on
        factors other than money, such as employee morale, environmental
        efficiency, and social license to operate.
      </li>
      <li className="mt-3">
        The problem we see is that more and more investors are becoming attuned
        to sustainability issues, leaving some companies behind to suffer
        financially. Inevitably, companies that do not consider their social
        responsibilities become poor investments.
      </li>
      <li className="mt-3">
        A company's social responsibility program often involves partnerships
        with the local community and charities. Issues of gender equality and
        diversity are also included in this category. Wealth managers are
        beginning to take CSR scores into account as one way of judging how well
        their clients are doing. Companies with ethical policies already have
        clear mandated rules about who they invest with, and managers of ethical
        funds report on their CSR policies with great rigour.
      </li>
      <li className="mt-3">
        The point is to avoid companies that are making headlines for the wrong
        reasons, but also those who are quietly doing it right behind the
        scenes.
      </li>

      <li className="mt-3 text-center" style={{ fontWeight: "800" }}>
        CSR investments have long-term benefits
      </li>
      <li className="mt-3">
        Concerning investing and corporate social responsibility, proponents of
        CSR argue that investors with a high percentage of exposure to ethical
        companies are less likely to face series of setbacks and shocks in their
        portfolio because of scandals or lawsuits.
      </li>
      <li className="mt-3">
        In this context, a good-run company will tend to focus on protecting its
        reputation by acting as a good corporate citizen to avoid reputational
        risks. According to an old theory, ethical investors should be willing
        to sacrifice some performance to be ethical. Numerous studies have
        demonstrated that this is not true, but the argument remains widespread.
      </li>
      <li className="mt-3">
        {" "}
        Performance is not everything. You must manage your risks. In most
        cases, transparent companies focus on long-term value creation because
        they have a more long-term outlook. When CSR is done with sincerity, it
        can boost a company's reputation, improve recruitment efforts and
        promote morale and teamwork. Additionally, CSR can result in measurable
        environmental benefits.
      </li>
      <li className="mt-3">
        Even the best corporate social responsibility program can't always
        compensate for the negative effects of an industry's social and
        environmental impact. Take the tobacco industry as an example. Although
        tobacco companies are actively engaged in philanthropic projects and
        education, often through grants, scholarships, professorships, and even
        the creation of schools, their core business model is based on a product
        that can kill.
      </li>
      <li className="mt-3">
        A company's corporate social responsibility must be integrated into its
        business model or else it is little more than an afterthought. This is
        necessary for building long term relations.{" "}
      </li>
      <li className="my-3">
        When good investors seek to invest in profitable CSRs with efficient
        investment management, there is employee growth. This employee growth
        leads to a growth of the company and thus, leading to major profits.
        This, again, helps in attracting good investors. The way these factors
        are dependent on each other must not be ignored. Good investors connect
        the corporate world to the world of non-profits. 9th block aims to play
        a similar role in helping to attract good investments and building an
        efficient management system to play the larger role of bridging the
        corporate world to the world of NGOs/non-profits.
      </li>
    </div>
  );
}

export default CsrInvestment;
